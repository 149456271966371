import {navigate} from 'gatsby';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import cookie from 'react-cookies';
import add from 'date-fns/add';
import toDate from 'date-fns/toDate';
import styles from './payments.module.scss';
import Api from '../api';
import LtvService from './LtvService';
import PurchaseUtils from './Utils/PurchaseUtils';
import {PlanDetails as PremiumPlanDetails,
  PriceMapping as PremiumPlanPriceMapping} from './Data/Prices/premium-plan';
import {
  SUBSCRIPTION_VERSION, euCountryList as EU_COUNTRY_LIST,
  lowerUSDCountryList as LOWER_USD_COUNTRY_LIST,
  lifetimeSlugs as LIFETIME_SLUGS,
  subscriptionSlugs as SUBSCRIPTION_SLUGS,
  inertMembershipStatuses as INERT_MEMBERSHIP_STATUSES,
} from '../containers/Constants';
import Error from './Utils/Error';
import * as Utils from './Utils';
import PaymentHeader from './Shared/PaymentHeader';
import PaymentFooter from './Shared/PaymentFooter';
import FAQSection from './FAQSection';
import * as AuthService from '../Application/Authentication/AuthService';
import {KeyValuePair} from '../Application/Shared';
import ProductCard from './ProductCard';
import PaymentSection from './PaymentSection';
import BillingConsent from './BillingConsent';
import LazyLoad from 'react-lazyload';
import {Helmet} from 'react-helmet';

// TODO: move processing to generic top layers
// TODO: css split up
// TODO: integrate checkout.com

// TODO: ditch service approach
const ltvService = new LtvService();

const VALID_FREE_TRIAL_DAYS = [14, 28, 30, 60];

const checkTaxDisclaimerEligibility = (student) => {
  if (['us'].includes(student?.attributes.address.country)) {
    return true;
  }
  return false;
};

const getCouponDetails = (couponCode) => {
  let coupon = null;
  let discountPercentage = null;
  try {
    coupon = atob(couponCode);
    if (coupon.indexOf('sc-1m-ft_') < 0) {
      coupon = null;
    } else {
      discountPercentage = coupon.split('_')[1];
    }
  } catch (error) {
    // ignore
  }
  return {
    coupon,
    discountPercentage,
  };
};

const computeTotalPrice = (price, discountPercentage) => {
  const discount = Math.round(Math.round(discountPercentage * price) / 100);
  return (price - discount);
};

const getPlanId = (frequency, billingCountry,
    freeTrialPeriod=0, freeTrialPeriodUnit) => {
  const frequencyMapping = {
    1: '_monthly_',
    12: '_annual_',
  };
  let plan = 'sub_' + SUBSCRIPTION_VERSION + frequencyMapping[frequency];
  if (freeTrialPeriod) {
    plan += (freeTrialPeriod + freeTrialPeriodUnit[0] + '-trial_');
  } else {
    plan += '0d-trial_';
  }
  plan += billingCountry;
  return plan;
};

const getPlanDetails = (
    billingCountry, freeTrialPeriod, freeTrialPeriodUnit,
    activeBillingFrequency,
) => {
  const plan = getPlanId(activeBillingFrequency, billingCountry,
      freeTrialPeriod, freeTrialPeriodUnit);
  const currency = PremiumPlanPriceMapping[billingCountry]['currency_code'];
  const currencySymbol =
  PremiumPlanPriceMapping[billingCountry]['cur_symbol'];
  const price = PremiumPlanPriceMapping[billingCountry]['price'] *
  100 * activeBillingFrequency;
  const {billingPeriod, billingPeriodUnit} =
  PremiumPlanDetails[activeBillingFrequency];
  return {
    plan,
    billingPeriod,
    billingPeriodUnit,
    price,
    currency,
    currencySymbol,
  };
};

const prepareSubscription = (includedData) => {
  let subscription = null;
  if (includedData) {
    subscription = includedData.filter((data) => {
      return data.type==='subscriptions';
    })[0];
  }
  return subscription;
};

const SubscriptionPage = ({data}) => {
  const pageData = data.shawWebsiteAppsubscriptionpage;
  const pageOfferTypeTexts =
    new KeyValuePair(pageData.offertypetexts.value);
  const pageLabels = new KeyValuePair(pageData.paymentsection.texts.value);
  const locale = data.shawWebsiteAppsubscriptionpage.locale;
  const language = locale?.split('-')[0] || 'en';
  const [errorPointers, setErrorPointers] = useState({});
  const [timeoutId, setTimeoutId] = useState(false);
  const [loadingState, setLoadingState] = useState('Please wait...');
  const [reactivationPrompt, promptForReactivation] = useState(false);
  const [activeBillingFrequency, setActiveBillingFrequency]=useState(1);
  const [showPlanSwitcher, setShowPlanSwitcher]=useState(false);
  const [product, setProduct] = useState({
    plan: null,
    coupon: null,
    billingPeriod: null,
    billingPeriodUnit: null,
    freeTrialPeriod: null,
    freeTrialPeriodUnit: null,
    price: null,
    totalPrice: null,
    discountPercentage: null,
    currency: null,
    currencySymbol: null,
    billingCountry: null,
  });
  const [billingAddress, setBillingAddress] = useState({
    country: '',
    stateCode: '',
    zip: '',
  });
  const [subscription, setSubscription] = useState(null);
  // const [skipPaymentEnabled, enableSkipPayment] = useState(false);
  const studentId = cookie.load('shawacademy_leadId');
  const productCode = 'sc-1m-ft';
  let couponCode = null; // encoded code

  // Donot do localisation of product data. Tweak at presentation if needed.
  const prepareProduct = (student) => {
    let billingCountry = 'us'; // default billing
    if (PremiumPlanPriceMapping[student.attributes.address.country]) {
      billingCountry = student.attributes.address.country;
    } else if (EU_COUNTRY_LIST
        .includes(student.attributes.address.country)) {
      billingCountry = 'eu';
    } else if (LOWER_USD_COUNTRY_LIST
        .includes(student.attributes.address.country)) {
      billingCountry = '3w';
    }
    let freeTrialPeriod = 28;
    if (
      VALID_FREE_TRIAL_DAYS.includes(student.attributes.profile.free_trial_days)
    ) {
      freeTrialPeriod = student.attributes.profile.free_trial_days;
    }
    const freeTrialPeriodUnit = 'days';
    const {
      plan, billingPeriod, billingPeriodUnit,
      price, currency, currencySymbol,
    } = getPlanDetails(billingCountry, freeTrialPeriod,
        freeTrialPeriodUnit, activeBillingFrequency);
    let totalPrice = price;
    let coupon = null;
    let discountPercentage = null;
    const couponCodeFromQuery=Utils.getParameterByName('coupon');

    if (activeBillingFrequency === 12) {
      couponCode=`c2MtMW0tZnRfNzA`;
    } else {
      if (couponCodeFromQuery) {
        couponCode=couponCodeFromQuery;
      } else if (student.attributes.profile?.['offer_type'] === 'CC20') {
        couponCode=`c2MtMW0tZnRfMjA`;
      }
    }
    ({coupon, discountPercentage} = getCouponDetails(couponCode));
    totalPrice = computeTotalPrice(price, discountPercentage);
    const updatedProduct = {
      ...product,
      plan,
      coupon,
      billingPeriod,
      billingPeriodUnit,
      freeTrialPeriod,
      freeTrialPeriodUnit,
      price,
      totalPrice,
      discountPercentage,
      currency,
      currencySymbol,
      billingCountry,
    };
    return updatedProduct;
  };

  const setMockData = () => {
    const studentCountry =
    cookie.load('student_country_code')?.toLowerCase();
    const studentRegion =
    cookie.load('student_region_code')?.toLowerCase();
    const studentCity=cookie.load('geotargetly_city_name')?.toLowerCase();
    const membershipSlug=cookie.load('student_membership_slug')?.toLowerCase();
    const membershipStatus=
    cookie.load('student_membership_status')?.toLowerCase();
    if (studentCountry) {
      // mock student data
      const studentMock = {
        'type': 'students',
        'id': studentId,
        'attributes': {
          'name': {
            'first_name': '',
            'last_name': '',
          },
          'membership': {
            'slug': membershipSlug,
            'name': 'Premium Membership',
            'status': membershipStatus,
          },
          'address': {
            'city': studentCity,
            'region': studentRegion,
            'country': studentCountry,
          },
          'profile': {
            'free_trial_days': 28,
            'course_picked': cookie.load('coursePicked'),
          },
        },
      };
      // setStudent(studentMock);
      const productMock= prepareProduct(studentMock);
      setProduct(productMock);
      setLoadingState(false);
      return studentMock;
    }
  };
  const [student, setStudent] = useState(() => setMockData());

  const freeTrialDays = student?.attributes.profile.free_trial_days;
  let currentOfferTypeText = pageOfferTypeTexts.getValue(freeTrialDays);
  if (!currentOfferTypeText) {
    currentOfferTypeText = `4 weeks free`;
  }
  const showTaxDisclaimer = checkTaxDisclaimerEligibility(student);

  useEffect(() => {
    if (studentId) {
      checkEligibilityForPlanSwitcher();
      loadDependencies(studentId);
    } else {
      handleError(new Error({
        type: Error.TYPES.INTERNAL_ERROR,
        message: `Kindly login to continue!`,
        nextAction: Error.NEXT_ACTIONS.REDIRECT_TO_LOGIN,
      }));
    }
  }, [studentId]);

  useEffect(() => {
    if (product.plan) {
      const updatedProduct=prepareProduct(student);
      setProduct(updatedProduct);
    }
  }, [activeBillingFrequency]);

  const loadDependencies = async (studentId) => {
    try {
      const {student, subscription} =
        await fetchStudentAndSubscription(studentId);
      setStudent(student);
      if (window.fbq) {
        window.fbq('init', '781932749175325', {
          em: student.attributes.email,
          fn: student.attributes.name.first_name,
          ln: student.attributes.name.last_name,
        });
      }
      setSubscription(subscription);
      setBillingAddress({
        ...billingAddress,
        country: student.attributes.address.country,
      });
      validateMembershipEligibility(student);
      // if (Utils.eligibleForSubReactivation(subscription)) {
      //   promptForReactivation(true);
      // }
      fetchLtv(student);
      const product = prepareProduct(student);
      Utils.triggerPageViewAction(
          student.id,
          Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
          {
            courseSlug: student.attributes.profile.course_picked,
            billingFrequency: product.billingPeriod,
          },
      );
      setProduct(product);
      setLoadingState(false);
    } catch (error) {
      handleError(error);
    }
  };
  const fetchLtv = (student) => {
    const country = student.attributes.address.country;
    const region = student.attributes.address.region;
    const utmSource = student.attributes.profile.utm_source;
    const utmMedium = student.attributes.profile.utm_medium;
    const age = student.attributes.profile.age;
    const gender = student.attributes.profile.gender;
    const goals = student.attributes.profile.goals;
    let courseId = student.attributes.profile.course_picked;
    if (cookie.load('coursePicked')) {
      courseId = cookie.load('coursePicked');
    }
    ltvService
        .fetchLtv(
            country, utmSource, utmMedium, age, gender, courseId, productCode,
            goals, region,
        );
  };

  const fetchStudentAndSubscription = async (studentId) => {
    try {
      const apiResponse =
        await new Api.Student(studentId).listLeadAndSubscription();
      const student = prepareStudent(apiResponse.data);
      const subscription = prepareSubscription(apiResponse.data.included);
      return {student, subscription};
    } catch (error) {
      if (error.response && [400, 404].includes(error.response.status)) {
        throw new Error({
          type: Error.TYPES.API_ERROR,
          message: `Kindly login to continue!`,
          nextAction: Error.NEXT_ACTIONS.REDIRECT_TO_LOGIN,
          sourceError: error,
        });
      } else {
        Utils.triggerApiErrorAction(
            Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
            error.config?.url, error.response?.status,
        );
        throw new Error({
          type: Error.TYPES.API_ERROR,
          message: `Something went wrong. Reload the page and retry!`,
          nextAction: Error.NEXT_ACTIONS.REFRESH_AND_RETRY,
          sourceError: error,
        });
      }
    }
  };

  const prepareStudent = (studentData) => {
    const student = {...studentData.data};
    student.attributes.membership = student.attributes.membership || {
      'slug': 'premium',
      'name': 'Premium Membership',
      'status': 'inactive',
    };
    const address = student.attributes.address || {};
    address.country = address.country?.toLowerCase();
    address.region = address.region?.toLowerCase();
    address.city = address.city?.toLowerCase();
    student.attributes.address = address;
    if (student.attributes.email.endsWith('@mailinator.com')) {
      student.attributes.isMailinatorUser = true;
    }
    const profile = student.attributes.profile || {};
    profile.gender = profile.gender?.toLowerCase();
    student.attributes.profile = profile;
    return student;
  };

  const getTrialEndDate = () => {
    let trialEndDate = new Date();
    if (product.freeTrialPeriod) {
      const freeTrialPeriod = product.freeTrialPeriod;
      trialEndDate = add(new Date(), {days: freeTrialPeriod});
    }
    return trialEndDate;
  };

  const validateMembershipEligibility = (student) => {
    // send premium inactive to signup for migration...
    if (student.attributes.membership.slug === 'premium' &&
      // eslint-disable-next-line max-len
      (['inactive', 'cancelled'].indexOf(student.attributes.membership.status) > -1)) {
      navigate('/signup/');
    }
    const isLifetimeUser =
      LIFETIME_SLUGS.includes(student.attributes.membership.slug);
    const isActiveSubscriber =
      SUBSCRIPTION_SLUGS
          .includes(student.attributes.membership.slug) &&
      !(INERT_MEMBERSHIP_STATUSES.
          includes(student.attributes.membership.status));
    if (isLifetimeUser || isActiveSubscriber) {
      throw new Error({
        type: Error.TYPES.INTERNAL_ERROR,
        message: `You already have membership with Shaw Academy.`,
        nextAction: Error.NEXT_ACTIONS.REDIRECT_TO_HOME,
      });
    }
  };

  const handleError = (error) => {
    console.log('Error:', error);
    switch (error.nextAction) {
      case Error.NEXT_ACTIONS.REDIRECT_TO_LOGIN:
        {
          setLoadingState(error.message);
          AuthService.logout();
          const loginUrl = `/app/${language}/login/`;
          Utils.redirectWithDelay(loginUrl);
        }
        break;

      case Error.NEXT_ACTIONS.REDIRECT_TO_HOME:
        {
          setLoadingState(error.message);
          const appUrl = `/app/${language}/`;
          Utils.redirectWithDelay(appUrl);
        }
        break;

      case Error.NEXT_ACTIONS.UNKNOWN:
        {
          if (Object.keys(error.errorPointers).length > 0) {
            displayError(error.errorPointers);
          } else {
            setLoadingState('Something went wrong. Reload the page and retry!');
          }
        }
        break;

      case Error.NEXT_ACTIONS.REFRESH_AND_RETRY:
        {
          setLoadingState(error.message);
        }
        break;

      default:
        setLoadingState('Something went wrong. Reload the page and retry!');
        break;
    }
  };

  const displayError = (errorPointers) => {
    // only displaying one of the errors
    // TODO: tweak for individual field error handling
    for (const pointer in errorPointers) {
      if (errorPointers.hasOwnProperty(pointer)) {
        const errorMessage = errorPointers[pointer];
        setErrorPointers({
          '_all': errorMessage,
        });
        setLoadingState(false);
        if (timeoutId) {
          clearInterval(timeoutId);
        }
        const tempTimeoutId = setTimeout(() => {
          setErrorPointers({});
        }, 5000);
        setTimeoutId(tempTimeoutId);
        break;
      }
    }
  };

  const onPurchaseSuccess = async (
    gateway, gatewayId, cardMeta = {}, completed=true,
  ) => {
    setLoadingState('Please wait while we verify your payment');
    const expires = toDate(add(new Date(), {minutes: 5}));
    cookie.save('subscription_active', 'true',
        {path: '/', domain: process.env.CookieDomain, expires});
    try {
      await upsertStudentProfile();
    } catch (error) {
      // ignore
      console.log('Failed to upsert student profile', error);
    }
    let coursePicked = student.attributes.profile.course_picked;
    if (cookie.load('coursePicked')) {
      coursePicked = cookie.load('coursePicked');
    }
    Utils.triggerSubscriptionTrialAction(
        student.id, Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        gateway, gatewayId, cardMeta.cardType, cardMeta.cardBrand,
        product.plan, product.coupon, ltvService.getCurrentLtv(),
        ltvService.getCurrentLtv2(), coursePicked, product.freeTrialPeriod,
    );
    try {
      if (window._talkableq) {
        window._talkableq.push(['authenticate_customer', {
          email: student.attributes.email,
          first_name: student.attributes.name.first_name,
          last_name: student.attributes.name.last_name,
          traffic_source: 'source',
          customer_id: student.id,
        }]);
        window._talkableq.push(['register_event', {
          event: {
            event_category: 'issue_reward',
            event_number: student.id,
          },
        }]);
      } else {
        console.log('talkable not available');
      }
    } catch (e) {

    }
    if (completed) {
      const localeArray=['de', 'fr', 'it', 'es', 'pt'];
      const thankYouPageUrl =
      localeArray.includes(language)?`/${language}/subscription/thank-you/`:
      `/subscription/thank-you/`;
      Utils.triggerPageViewAction(
          student.id,
          Utils.PAGE_KINDS.REDIRECTION_TO_THANKYOU,
      );
      const stateObj={
        state: {
          student: {
            attributes: {
              address: {
                country: student?.attributes?.address?.country,
              },
              name: {
                first_name: student?.attributes?.name?.first_name,
                last_name: student?.attributes?.name?.last_name,
              },
              profile: {
                course_picked: coursePicked,
              },
            },
          },
          product: {
            freeTrialPeriod: product?.freeTrialPeriod,
            freeTrialPeriodUnit: product?.freeTrialPeriodUnit,
            gateway,
          },
        },
      };
      navigate(thankYouPageUrl, stateObj);
    }
  };

  const onReactivationSuccess = async (
    gateway, gatewayId, cardMeta = {},
  ) => {
    setLoadingState('Please wait while we verify your payment');
    const expires = toDate(add(new Date(), {'minutes': 5}));
    cookie.save('subscription_active', 'true',
        {path: '/', domain: process.env.CookieDomain, expires});
    try {
      await upsertStudentProfile('reactivated');
    } catch (error) {
      // ignore
      console.log('Failed to upsert student profile', error);
    }
    Utils.triggerSubscriptionChangeAction(
        student.id, product.plan, 'Reactivated',
        Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        gateway, gatewayId, cardMeta.cardType, cardMeta.cardBrand,
        product.freeTrialPeriod,
    );
    let coursePicked = student.attributes.profile.course_picked;
    if (cookie.load('coursePicked')) {
      coursePicked = cookie.load('coursePicked');
    }
    Utils.triggerSubscriptionTrialAction(
        student.id, Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        gateway, gatewayId, cardMeta.cardType, cardMeta.cardBrand,
        product.plan, product.coupon, ltvService.getCurrentLtv(),
        ltvService.getCurrentLtv2(), coursePicked, product.freeTrialPeriod,
    );

    const localeArray=['de', 'fr', 'it', 'es', 'pt'];
    const thankYouPageUrl =
    localeArray.includes(language)?`/${language}/subscription/thank-you/`:
    `/subscription/thank-you/`;
    Utils.triggerPageViewAction(
        student.id,
        Utils.PAGE_KINDS.REDIRECTION_TO_THANKYOU,
    );
    const stateObj={
      state: {
        student: {
          attributes: {
            address: {
              country: student?.attributes?.address?.country,
            },
            name: {
              first_name: student.attributes.name?.first_name,
              last_name: student.attributes.name?.last_name,
            },
            profile: {
              course_picked: coursePicked,
            },
          },
        },
        product: {
          freeTrialPeriod: product.freeTrialPeriod,
          freeTrialPeriodUnit: product.freeTrialPeriodUnit,
        },
      },
    };
    navigate(thankYouPageUrl, stateObj );
  };

  const upsertStudentProfile = (action) => {
    const currentDate = new Date();
    const profileEntries = [];
    if (action === 'reactivated') {
      profileEntries.push({
        'registration_id': null,
        'key': 'reactivatedDate',
        'value': currentDate.toISOString(),
      });
    } else {
      profileEntries.push({
        'registration_id': null,
        'key': 'predicted_ltv',
        'value': ltvService.getCurrentLtv(),
      });
      profileEntries.push({
        'registration_id': null,
        'key': 'offerStartDate',
        'value': currentDate.toISOString(),
      });
      profileEntries.push({
        'registration_id': null,
        'key': 'offerEndDate',
        'value': getTrialEndDate().toISOString(),
      });
    }
    if (student.relationships?.studentprofiles?.data?.[0]?.id) {
      // Update profile
      const payload = {
        data: {
          type: 'studentprofiles',
          id: student.relationships.studentprofiles.data[0].id,
          attributes: {
            items: profileEntries,
          },
          relationships: {
            student: {
              data: {
                type: 'student',
                id: student.id,
              },
            },
          },
        },
      };
      return new Api.Profile(
          student.relationships.studentprofiles.data[0].id,
      ).updateProfile(payload);
    } else {
      // Create profile
      const payload = {
        data: {
          type: 'studentprofiles',
          attributes: {
            items: profileEntries,
          },
          relationships: {
            student: {
              data: {
                type: 'student',
                id: student.id,
              },
            },
          },
        },
      };
      return new Api.Profile().createProfile(payload);
    }
  };

  const checkEligibilityForPlanSwitcher = () => {
    setShowPlanSwitcher(true);
  };

  const getFreeTrialPeriodText = () => {
    let freeTrialText = `4 Weeks`; // default text
    if (product.freeTrialPeriod > 0) {
      if (product.freeTrialPeriod === 14) {
        freeTrialText = `2 Weeks`;
      } else if (product.freeTrialPeriod != 28) {
        freeTrialText =
          `${product.freeTrialPeriod} ${product.freeTrialPeriodUnit}`;
      }
    }
    return freeTrialText;
  };

  const getBillingPeriodText = () => {
    const billingPeriodText =
      (product.billingPeriod!==1?`${product.billingPeriod} `:``) +
      `${pageLabels.getValue(product.billingPeriodUnit?.toUpperCase())}`;
    return billingPeriodText;
  };

  const handleReactivation = (e) => {
    setLoadingState('Your request is being processed. Please wait...');
    const ctaLabel = e.target?.textContent;
    Utils.triggerCTAClickAction(student.id,
        Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        ctaLabel, 'Reactivate');
    promptForReactivation(false);
    processReactivation();
  };

  const processReactivation = async () => {
    try {
      const oneClickReactivationParams = {
        studentId: student.id,
        subscription,
      };
      await PurchaseUtils.oneClickReactivateSubscription(
          oneClickReactivationParams,
      );
      onReactivationSuccess();
    } catch (error) {
      console.log('One click reactivation failed', error.message);
      // ignore and allow payment method option
      displayError({
        '_all': `Failed to reactivate. Try updating payment method`,
      });
    }
  };

  const billingConsent = <BillingConsent
    getFreeTrialPeriodText={getFreeTrialPeriodText}
    product={product}
    language={language}
    getBillingPeriodText = {getBillingPeriodText}
    data={pageData.paymentsection}
    showTaxDisclaimer={showTaxDisclaimer}
  />;
  return (
    <div className={styles.paymentContainer}>
      <Helmet> <meta name="robots" content="nofollow, noindex" /></Helmet>
      <PaymentHeader
        student={student}
      />
      <div className={styles.paymentBody}>
        <div className={styles.paymentContext}>
          <ProductCard data={pageData.productsection}
            showTaxDisclaimer={showTaxDisclaimer}
            language={language}
            getFreeTrialPeriodText={getFreeTrialPeriodText}
            getFormattedPrice={Utils.getFormattedPrice}
            getTrialEndDate={getTrialEndDate}
            product={product}
            student={student}
            showPlanSwitcher={showPlanSwitcher}
            activeBillingFrequency={activeBillingFrequency}
            setActiveBillingFrequency={setActiveBillingFrequency}
          />
          <LazyLoad height={490} offset={0} once={true}>
            <FAQSection
              title={pageData.faqsection.title}
              questions={pageData.faqsection.questions}
              isForMobile={false}
            />
          </LazyLoad>
        </div>
        <PaymentSection
          pageData={pageData}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          errorPointers={errorPointers}
          setLoadingState={setLoadingState}
          handleError={handleError}
          // skipPaymentEnabled={skipPaymentEnabled}
          onPurchaseSuccess={onPurchaseSuccess}
          onReactivationSuccess={onReactivationSuccess}
          product={product}
          student={student}
          subscription={subscription}
          language={language}
        />
      </div>
      <LazyLoad height={54} offset={0} once={true}>
        <PaymentFooter footerData={pageData.footer}/>
      </LazyLoad>
      {!!loadingState && <div className={styles.shPaymentLoadingModal}>
        <div className={styles.modalBackdrop}></div>
        <div className={styles.shModalCotent}>
          <span className={styles.loader}></span>
          <p className={styles.loaderMessage}>
            {loadingState}
          </p>
        </div>
      </div>}
      {reactivationPrompt &&
      <div className={styles.reactivationPopup}>
        <div className={styles.content}>
          <span className={styles.closeModal}
            onClick={() => promptForReactivation(false)}>×</span>
          <p>
            {pageData.reactivationpopup.popuptext}
          </p>
          <div className={styles.actions}>
            <Button className={styles.active} onClick={handleReactivation}>
              {pageData.reactivationpopup.cta}
            </Button>
          </div>
        </div>
      </div>}
    </div>
  );
};

SubscriptionPage.propTypes = {
  data: PropTypes.object,
};

export default SubscriptionPage;
