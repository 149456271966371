import GatewayProcessor from './GatewayProcessor';


/**
 *
 *
 * @class ChargebeeProcessor
 */
class ChargebeeProcessor extends GatewayProcessor {
  /**
   * Creates an instance of ChargebeeProcessor.
   * @param {*} chargebeeGatewayId
   * @memberof ChargebeeProcessor
   */
  constructor(chargebeeGatewayId) {
    super('chargebee');
    this.gatewayAccountId = chargebeeGatewayId;
  }

  /**
   *
   *
   * @return {*}
   * @memberof ChargebeeProcessor
   */
  isPaymentIntentSupported() {
    return false;
  }

  /**
   *
   *
   * @param {*} {paymentIntent}
   * @return {*}
   * @memberof ChargebeeProcessor
   */
  filterPurchaseParams({cbToken, issuingCountry}) {
    return {
      cb_token: cbToken,
    };
  }
}

export default ChargebeeProcessor;
