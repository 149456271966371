import axios from 'axios';
/**
 *
 *
 * @class LtvService
 */
class LtvService {
  static STATES = {
    WAITING: 'waiting',
    LOADING: 'loading',
    COMPLETE: 'complete',
    FAILED: 'failed',
  }

  /**
   * Creates an instance of LtvService.
   * @memberof LtvService
   */
  constructor() {
    this.initState();
  }

  /**
   *
   *
   * @memberof LtvService
   */
  initState = () => {
    this.state = LtvService.STATES.WAITING;
    this.predictedLtv = 0;
    this.predictedLtv2 = 0;
  }

  /**
   *
   *
   * @param {*} country
   * @param {*} utmSource
   * @param {*} utmMedium
   * @param {*} age
   * @param {*} gender
   * @param {*} courseId
   * @param {*} productCode
   * @param {*} goal
   * @param {*} region
   * @memberof LtvService
   */
  fetchLtv =
    async (
      country, utmSource, utmMedium, age, gender, courseId, productCode,
      goal, region,
    ) => {
      this.initState();
      try {
        if (!courseId) {
          courseId = 'no-course';
        }
        let ltvAge = age;
        if (ltvAge === 'Less than 18') {
          ltvAge = '<18';
        }
        const domain = process.env.PaymentWebDomain;
        const requestUrl = domain + '/assets/json/LTV/v2/facebook/' + country +
          '/' + courseId + '/ltv.json';
        let tmpLTV = 0;
        let tmpLTV2 = 0;
        const response = await axios.get(requestUrl);
        const ltvRegion = (region) ? region.toUpperCase() : '';
        const ltvAgeGenderKey = ltvAge + ',' + gender.toLowerCase();
        const ltvGoalkey = ltvAgeGenderKey + ',' + goal;
        const ltvRegionkey = ltvGoalkey + ',' + ltvRegion;
        if (response.data[ltvRegionkey]) {
          tmpLTV = parseFloat(response.data[ltvRegionkey]);
          tmpLTV2 = parseFloat(response.data[ltvRegionkey]);
        } else if (response.data[ltvGoalkey]) {
          tmpLTV = parseFloat(response.data[ltvGoalkey]);
          tmpLTV2 = parseFloat(response.data[ltvGoalkey]);
        } else if (response.data[ltvAgeGenderKey]) {
          tmpLTV = parseFloat(response.data[ltvAgeGenderKey]);
          tmpLTV2 = parseFloat(response.data[ltvAgeGenderKey]);
        } else if (response.data[ltvAge]) {
          tmpLTV = parseFloat(response.data[ltvAge]);
          tmpLTV2 = parseFloat(response.data[ltvAge]);
        } else if (response.data['default']) {
          tmpLTV = parseFloat(response.data['default']);
          tmpLTV2 = parseFloat(response.data['default']);
        }
        let modifiedLtv = tmpLTV;
        if (country === 'in') {
          modifiedLtv = Math.min(modifiedLtv, 19.78);
        }
        let modifiedLtv2 = tmpLTV2;
        if (country === 'in') {
          modifiedLtv2 = Math.min(modifiedLtv2, 19.78);
        }
        this.predictedLtv = modifiedLtv;
        this.predictedLtv2 = modifiedLtv2;
        this.state = LtvService.STATES.COMPLETE;
      } catch (error) {
        console.log('LTV Fetch failed', error.message);
        this.predictedLtv = 33.0;
        this.predictedLtv2 = 25.0;
        this.state = LtvService.STATES.FAILED;
        window.dataLayer.push({
          'event': 'track-action',
          'event-param': {
            'event': 'ltv-fetch-failed',
            'parameter': {
              'error': error,
              'utm_source': utmSource,
              'utm_medium': utmMedium,
              'product_code': productCode,
              'course_id': courseId,
            },
          },
        });
      }
    }

  // no guarantee that api data is reflected
  getCurrentLtv = () => {
    return this.predictedLtv;
  }

  // no guarantee that api data is reflected
  getCurrentLtv2 = () => {
    return this.predictedLtv2;
  }

  getCurrentState = () => {
    return this.state;
  }
}

export default LtvService;
