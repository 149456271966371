// Timetable page
export const graphActualPointColor='#eb5b77';
export const graphGoalPointColor='#a8abbf';
export const borderColor='#b3ebe2';

export const confettiColors=
['#eb5b77', 'green', 'yellow', 'orange', 'aqua', 'purple', 'lightblue'];

export const languageMapping = {
  'en': 'ENGLISH',
  'fr': 'FRENCH',
  'de': 'GERMAN',
  'es': 'SPANISH',
  'it': 'ITALIAN',
  'pt': 'PORTUGUESE',
};

// TODO: THIS NEEDS TO BE OUT OF HERE.
export const userProfileConstants = {

  industry: [
    {key: 'account-management', value: 'Account Management'},
    {key: 'architecture', value: 'Architecture'},
    {key: 'arts', value: 'Arts'},
    {key: 'aviation', value: 'Aviation'},
    {key: 'construction-engineering', value: 'Construction Engineering'},
    {key: 'farming-agriculture', value: 'Farming Agriculture'},
    {key: 'financial-services', value: 'Financial Services'},
    {key: 'academic-student', value: 'Academic Student'},
    {key: 'government-public-sector', value: 'Government Public Sector'},
    {key: 'graduate', value: 'Graduate'},
    {key: 'healthcare', value: 'Healthcare'},
    {key: 'hospitality', value: 'Hospitality'},
    {key: 'hr-recruitment', value: 'HR Recruitment'},
    {key: 'it-programming', value: 'IT Programming'},
    {key: 'legal', value: 'Legal'},
    {key: 'marketing-advertising-pr', value: 'Marketing Advertising Pr'},
    {key: 'media', value: 'Media'},
    {key: 'property-auctioneer', value: 'Property Auctioneer'},
    {key: 'office-services-admin-customer-service',
      value: 'Office Services Admin Customer Service'},
    {key: 'photography', value: 'Photography'},
    {key: 'qa-quality', value: 'QA Quality'},
    {key: 'r&d-science', value: 'R & D Science'},
    {key: 'retail', value: 'Retail'},
    {key: 'retired', value: 'Retired'},
    {key: 'sales-business-development', value: 'Sales Business Development'},
    {key: 'senior-management', value: 'Senior Management'},
    {key: 'training-education', value: 'Training Education'},
    {key: 'unemployed', value: 'Unemployed'},
    {key: 'warehousing-logistics-transportation',
      value: 'Warehousing Logistics Transportation'},
  ],

  gender: [
    {key: 'male', value: 'Male'},
    {key: 'female', value: 'Female'},
    {key: 'other', value: 'Other'},
  ],

  degree: [
    {key: 'secondary-2nd-level', value: 'Secondary / 2nd Level'},
    {key: 'post-graduate', value: 'Postgraduate'},
    {key: 'graduate', value: 'Graduate'},
    {key: 'master-degree', value: 'Master Degree'},
  ],

  age: [
    {key: 'Less than 18', value: 'Less than 18'},
    {key: '18-21', value: '18-21'},
    {key: '22-25', value: '22-25'},
    {key: '26-30', value: '26-30'},
    {key: '31-35', value: '31-35'},
    {key: '36-40', value: '36-40'},
    {key: '41-45', value: '41-45'},
    {key: '46-50', value: '46-50'},
    {key: '51-55', value: '51-55'},
    {key: '56-60', value: '56-60'},
    {key: '61-65', value: '61-65'},
    {key: '65+', value: '65+'},
  ],

  goals: [
    {key: 'gain-promotion', value: 'Gain Promotion'},
    {key: 'enter-new-industry', value: 'Enter New Industry'},
    {key: 'grow-my-business', value: 'Grow My Business'},
    {key: 'self-improvement', value: 'Self Improvement'},
    {key: 'start-business', value: 'Start a Business'},
    {key: 'hobby', value: 'Hobby'},
    {key: 'professional-improvement', value: 'Professional Improvement'},
  ],

  employment: [
    {key: 'student', value: 'Student'},
    {key: 'employed-full-time',
      value: 'Employed full time (35+ hours per week)'},
    {key: 'employed-part-time',
      value: 'Employed part time (less than 35 hours per week)'},
    {key: 'self-employed-full-time',
      value: 'Self-employed full time'},
    {key: 'self-employed-part-time-freelancer',
      value: 'Self-employed part time/ Freelancer'},
    {key: 'homemaker-taking-care-of-family',
      value: 'Homemaker/Taking Care of Family'},
    {key: 'on-sabbatical-maternity-paternity-leave',
      value: 'On Sabbatical/ Maternity/ Paternity Leave'},
    {key: 'unemployed-and-looking-for-work',
      value: 'Unemployed and looking for work'},
    {key: 'unemployed-but-not-looking-for-work',
      value: 'Unemployed but not looking for work'},
    {key: 'retired',
      value: 'Retired'},
    {key: 'unable-to-work',
      value: 'Unable to work'},
  ],
};

export const SUBSCRIPTION_VERSION = 'v17';

export const actionMapping = {
  'add-course': 'New Course Added',
  'watch-lesson-live': 'Lesson Watched Live',
  'watch-lesson-recording': 'Lesson Recording Watched',
  'complete-lesson-live': 'Lesson Completed Live',
  'complete-lesson-recording': 'Lesson Recording Completed',
  'visit-members-area': 'Bonus for the day for logging in',
  'complete-weekly-assignment': 'Weekly Assignment Completed',
  'complete-final-assignment': 'Final Assignment Completed',
  'download-resources': 'Resource Downloaded',
  'add-profile-pic': 'Profile Pic Added',
  'registration-complete': 'Registration Complete',
  'first-schedule': 'First Course Scheduled',
};

export const lowerPlans = [{
  'ae': {
    'currency_code': 'aed',
    'cur_symbol': 'AED',
    'price': 79.99,
    'total_price': 79.99,
  },
  'au': {
    'currency_code': 'aud',
    'cur_symbol': 'AUD $',
    'price': 34.99,
    'total_price': 34.99,
  },
  'br': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'ca': {
    'currency_code': 'cad',
    'cur_symbol': 'CAD $',
    'price': 34.99,
    'total_price': 34.99,
  },
  'eg': {
    'currency_code': 'egp',
    'cur_symbol': 'EGP',
    'price': 125,
    'total_price': 125,
  },
  'eu': {
    'currency_code': 'eur',
    'cur_symbol': '€',
    'price': 24.99,
    'total_price': 24.99,
  },
  'gb': {
    'currency_code': 'gbp',
    'cur_symbol': '£',
    'price': 24.99,
    'total_price': 24.99,
  },
  'gh': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'id': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'in': {
    'currency_code': 'inr',
    'cur_symbol': '₹',
    'price': 300,
    'total_price': 300,
  },
  'jo': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'ke': {
    'currency_code': 'kes',
    'cur_symbol': 'KES',
    'price': 895,
    'total_price': 895,
  },
  'lk': {
    'currency_code': 'lkr',
    'cur_symbol': 'LKR',
    'price': 925,
    'total_price': 925,
  },
  'ma': {
    'currency_code': 'mad',
    'cur_symbol': 'MAD',
    'price': 90,
    'total_price': 90,
  },
  'mv': {
    'currency_code': 'mvr',
    'cur_symbol': 'MVR',
    'price': 249,
    'total_price': 249,
  },
  'my': {
    'currency_code': 'myr',
    'cur_symbol': 'RM',
    'price': 29.99,
    'total_price': 29.99,
  },
  'ng': {
    'currency_code': 'ngn',
    'cur_symbol': '₦',
    'price': 2750,
    'total_price': 2750,
  },
  'np': {
    'currency_code': 'npr',
    'cur_symbol': 'NPR',
    'price': 599,
    'total_price': 599,
  },
  'nz': {
    'currency_code': 'nzd',
    'cur_symbol': 'NZD $',
    'price': 34.99,
    'total_price': 34.99,
  },
  'om': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'ph': {
    'currency_code': 'php',
    'cur_symbol': '₱',
    'price': 249,
    'total_price': 249,
  },
  'pk': {
    'currency_code': 'pkr',
    'cur_symbol': 'PKR',
    'price': 675,
    'total_price': 675,
  },
  'pl': {
    'currency_code': 'pln',
    'cur_symbol': 'PLN',
    'price': 44.99,
    'total_price': 44.99,
  },
  'qa': {
    'currency_code': 'qar',
    'cur_symbol': 'QAR',
    'price': 49.99,
    'total_price': 49.99,
  },
  'sa': {
    'currency_code': 'sar',
    'cur_symbol': 'SAR',
    'price': 34.99,
    'total_price': 34.99,
  },
  'sg': {
    'currency_code': 'sgd',
    'cur_symbol': 'SGD $',
    'price': 19.99,
    'total_price': 19.99,
  },
  'tr': {
    'currency_code': 'try',
    'cur_symbol': 'TRY',
    'price': 34.99,
    'total_price': 34.99,
  },
  'tz': {
    'currency_code': 'tzs',
    'cur_symbol': 'TZS',
    'price': 13999,
    'total_price': 13999,
  },
  'ug': {
    'currency_code': 'ugx',
    'cur_symbol': 'UGX',
    'price': 29500,
    'total_price': 29500,
  },
  'us': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 34.99,
    'total_price': 34.99,
  },
  'za': {
    'currency_code': 'zar',
    'cur_symbol': 'ZAR',
    'price': 199,
    'total_price': 199,
  },
  'zw': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 6.99,
    'total_price': 6.99,
  },
  'default': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 34.99,
    'total_price': 34.99,
  },
}];
// TODO: I hope this is not used anywhere.
// Excellent example of how NOT to name variables
export const checkBoxArray = [
  'My current budget is limited',
  'I came for a free course only',
  'I don’t have enough time right now',
  'Lesson duration is too long to watch',
  'Platform is confusing or hard to use',
  'I had issues with customer/education support',
];
// TODO: PLEASE FORMAT IN A SENSIBLE WAY.
export const countryDetailsAndTimings = [{
  'in': {countryName: 'India', flagIcon: '🇮🇳',
    phone: '00080004 03084', timeZone: 'IST'},
  'au': {countryName: 'Australia', flagIcon: '🇦🇺',
    phone: '+61 1800 875 534', timeZone: 'AEST'},
  'gb': {countryName: 'UK', flagIcon: '🇬🇧',
    phone: '0808 169 6493', timeZone: 'BST'},
  'ie': {countryName: 'Ireland', flagIcon: '🇮🇪',
    phone: '+353 76 888 9089', timeZone: 'IST'},
  'be': {countryName: 'Belgium', flagIcon: '🇧🇪',
    phone: '+32 460 23 43 57', timeZone: 'CEST'},
  'dk': {countryName: 'Denmark', flagIcon: '🇩🇰',
    phone: '+45 92 45 39 40', timeZone: 'CEST'},
  'se': {countryName: 'Sweden', flagIcon: '🇸🇪',
    phone: '+46 10 551 39 59', timeZone: 'CEST'},
  'cl': {countryName: 'Chile', flagIcon: '🇨🇱',
    phone: '+56 2 2666 0913', timeZone: 'CLT'},
  'us': {countryName: 'US', flagIcon: '🇺🇸',
    phone: '+1 844 981 4345', timeZone: 'EST'},
  'ca': {countryName: 'Canada', flagIcon: '🇨🇦',
    phone: '+1 844 987 3907', timeZone: 'EST'},
  'za': {countryName: 'South Africa', flagIcon: '🇿🇦',
    phone: '+27 87 250 3245', timeZone: 'SAST'},
  'ph': {countryName: 'Philippines', flagIcon: '🇵🇭',
    phone: '+63 42 788 2178', timeZone: 'PHT'},
  'fi': {countryName: 'Finland', flagIcon: '🇫🇮',
    phone: '+358 75 3250929', timeZone: 'EEST'},
  'fr': {countryName: 'France', flagIcon: '🇫🇷',
    phone: '0801840606', timeZone: 'BST'},
}];

export const lifetimeSlugs = ['lifetime',
  'winback',
  'referred-by-member',
  'winback-99',
  'lifetime-unlimited',
  'winback-149',
  'lifetime-masters',
  'lifetime-prize-winner',
  'lifetime-photography',
  'lifetime-marketing',
  'lifetime-health-wellness',
  'winback-lifetime-membership-plus-199',
  'lifetime-design',
  'premium_plus_subscription',
  'lifetime-business',
  '12-month-prize-winner',
  'lifetime-technology',
  '3-month-prize-winner',
  '1-month-prize-winner',
  'advanced_member',
  '6-month-prize-winner',
  'winback-lifetime-membership-plus-179',
  'winback-lifetime-membership-plus-99',
  'family-premium',
  'investor-lifetime',
  'lifetime'];

export const subscriptionSlugs = [
  'premium', 'one-course-plan',
];

export const inertMembershipStatuses = [
  'inactive', 'non_renewing', 'cancelled',
];

export const discountedTierCodes = {
  'ae': 'yvjahu',
  'au': 'mfniny',
  'bd': 'dhdhhw',
  'br': 'oovtsa',
  'ca': 'vgbwjc',
  'cz': 'ywajdh',
  'eg': 'eduhbm',
  'eu': 'yvjahu',
  'gb': 'knvvfb',
  'gh': 'kbrnhg',
  'id': 'kbrnhg',
  'jo': 'kbrnhg',
  'ke': 'rdpjcv',
  'lk': 'ghnivo',
  'ma': 'ekzkki',
  'mv': 'eduhbm',
  'my': 'kikotm',
  'ng': 'sidcal',
  'np': 'eduhbm',
  'nz': 'ghnivo',
  'om': 'kbrnhg',
  'ph': 'kbrnhg',
  'pk': 'tkburl',
  'pl': 'ywajdh',
  'qa': 'iacjcp',
  'sa': 'kikotm',
  'sg': 'tqxibd',
  'tr': 'ejjhnr',
  'tz': 'tqxibd',
  'ug': 'tqxibd',
  'us': 'ahqkxt',
  'za': 'ypalam',
  'zw': 'kbrnhg',
  'in': 'kvdciq',
};

export const countryMapping = { // from content stack
  'eu': 'Europe',
  'us': 'USA',
  'gb': 'UK',
  'ca': 'Canada',
  'au': 'Australia',
  'za': 'South Africa',
  'lat': 'South America',
  'row': 'Rest of the World',
};

export const euCountryList = ['at', 'be', 'bg', 'hr',
  'cr', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'hu', 'ie',
  'it', 'lv', 'lt', 'lu', 'mt', 'aw', 'pl', 'pt', 'ro',
  'sk', 'is', 'es', 'se'];

export const countriesEligibleForNewSchedule = (country, createdAt) =>{
  const eligibleCountry = ['gb'];
  const leadEligibility = new Date(createdAt) >= new Date('2021-09-23');
  if (eligibleCountry.includes(country) && leadEligibility) {
    return true;
  }
};
export const countriesEligibleForWatchModule = ['gb'];

export const latCountryList = [
  'ar', 'bo', 'br', 'cl', 'co',
  'ec', 'fk', 'gf', 'gy', 'py',
  'pe', 'sr', 'uy', 've',
];

export const leaderBoardsEigibleCountries = [
  'us', 'gb', 'ca', 'au', 'za',
];

export const lowerUSDCountryList =
['ar', 'br', 'co', 'gh', 'id', 'jo', 'mx', 'om', 'pe', 'zw'];

export const lifeTimeDiscount = {
  'ae': {
    'currency_code': 'aed',
    'discount': 2551,
  },
  'au': {
    'currency_code': 'aud',
    'discount': 1625,
  },
  'bd': {
    'currency_code': 'bdt',
    'discount': 38501,
  },
  'ca': {
    'currency_code': 'cad',
    'discount': 1390,
  },
  'eg': {
    'currency_code': 'egp',
    'discount': 8501,
  },
  'eu': {
    'currency_code': 'eur',
    'discount': 926,
  },
  'gb': {
    'currency_code': 'gbp',
    'discount': 926,
  },
  'in': {
    'currency_code': 'inr',
    'discount': 31501,
  },
  'ke': {
    'currency_code': 'kes',
    'discount': 49500,
  },
  'lk': {
    'currency_code': 'lkr',
    'discount': 71750,
  },
  'ma': {
    'currency_code': 'mad',
    'discount': 4925,
  },
  'mv': {
    'currency_code': 'mvr',
    'discount': 7051,
  },
  'my': {
    'currency_code': 'myr',
    'discount': 2121,
  },
  'ng': {
    'currency_code': 'ngn',
    'discount': 143501,
  },
  'np': {
    'currency_code': 'npr',
    'discount': 49250,
  },
  'nz': {
    'currency_code': 'nzd',
    'discount': 1625,
  },
  'ph': {
    'currency_code': 'php',
    'discount': 22200,
  },
  'qa': {
    'currency_code': 'qar',
    'discount': 2551,
  },
  'sa': {
    'currency_code': 'sar',
    'discount': 2551,
  },
  'sg': {
    'currency_code': 'sgd',
    'discount': 661,
  },
  'tr': {
    'currency_code': 'try',
    'discount': 1751,
  },
  'us': {
    'currency_code': 'usd',
    'discount': 1161,
  },
  'za': {
    'currency_code': 'zar',
    'discount': 9751,
  },
  'default': {
    'currency_code': 'eur',
    'discount': 926,
  },
};

export const lifeTimeDiscountPrice = {
  'ae': {
    'currency_code': 'aed',
    'cur_symbol': 'AED',
    'price': 2750,
  },
  'au': {
    'currency_code': 'aud',
    'cur_symbol': 'AUD $',
    'price': 1750,
  },
  'bd': {
    'currency_code': 'bdt',
    'cur_symbol': 'BDT',
    'price': 42500,
  },
  'ca': {
    'currency_code': 'cad',
    'cur_symbol': 'CAD $',
    'price': 1495,
  },
  'eg': {
    'currency_code': 'egp',
    'cur_symbol': 'EGP',
    'price': 9500,
  },
  'eu': {
    'currency_code': 'eur',
    'cur_symbol': '€',
    'price': 995,
  },
  'gb': {
    'currency_code': 'gbp',
    'cur_symbol': '£',
    'price': 995,
  },
  'in': {
    'currency_code': 'inr',
    'cur_symbol': '₹',
    'price': 35000,
  },
  'ke': {
    'currency_code': 'kes',
    'cur_symbol': 'KES',
    'price': 55000,
  },
  'lk': {
    'currency_code': 'lkr',
    'cur_symbol': 'LKR',
    'price': 79500,
  },
  'ma': {
    'currency_code': 'mad',
    'cur_symbol': 'MAD',
    'price': 5500,
  },
  'mv': {
    'currency_code': 'mvr',
    'cur_symbol': 'MVR',
    'price': 7950,
  },
  'my': {
    'currency_code': 'myr',
    'cur_symbol': 'RM',
    'price': 2350,
  },
  'ng': {
    'currency_code': 'ngn',
    'cur_symbol': '₦',
    'price': 159500,
  },
  'np': {
    'currency_code': 'npr',
    'cur_symbol': 'NPR',
    'price': 55000,
  },
  'nz': {
    'currency_code': 'nzd',
    'cur_symbol': 'NZD $',
    'price': 1750,
  },
  'ph': {
    'currency_code': 'php',
    'cur_symbol': '₱',
    'price': 25950,
  },
  'qa': {
    'currency_code': 'qar',
    'cur_symbol': 'QAR',
    'price': 2750,
  },
  'sa': {
    'currency_code': 'sar',
    'cur_symbol': 'SAR',
    'price': 2750,
  },
  'sg': {
    'currency_code': 'sgd',
    'cur_symbol': 'SGD $',
    'price': 750,
  },
  'tr': {
    'currency_code': 'try',
    'cur_symbol': 'TRY',
    'price': 1950,
  },
  'us': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 1250,
  },
  'za': {
    'currency_code': 'zar',
    'cur_symbol': 'ZAR',
    'price': 10500,
  },
  'default': {
    'currency_code': 'eur',
    'cur_symbol': '€',
    'price': 995,
  },
};


// leaderboards
