import axios from 'axios';
import Error from './Error';
import * as Utils from '.';
import {
  PRE_AUTH_ADDON_PREFIX, PRE_AUTH_ADDON_VERSION,
} from '../Data/Prices/pre-auth';

/**
 *
 *
 * @class PurchaseUtils
 */
class PurchaseUtils {
  /**
   * Creates an instance of PurchaseUtils.
   * @param {*} gatewayProcessor
   * @memberof PurchaseUtils
   */
  constructor(gatewayProcessor) {
    this.gatewayProcessor = gatewayProcessor;
  }

  /**
   *
   *
   * @param {*} {
   *     product, studentId, cardHolder,
   *     billingAddress, coursePicked, ...gatewayParams
   *   }
   * @return {*}
   * @memberof PurchaseUtils
   */
  async createPaymentIntent({
    product, studentId, cardHolder,
    billingAddress, coursePicked, pageKind, ...gatewayParams
  }) {
    const paymentIntentPayload = {
      gateway: this.gatewayProcessor.getName(),
      gateway_account_id: this.gatewayProcessor.gatewayAccountId,
      currency_code: product.currency,
      student_id: studentId,
      card_holder_name: cardHolder,
      billing_address: PurchaseUtils.extractBillingAddress(billingAddress),
      course_id: coursePicked,
      ...(this.gatewayProcessor.filterPaymentIntentParams(gatewayParams)),
    };
    if (product?.plan) {
      paymentIntentPayload.purchase_item_type = 'subscription';
      paymentIntentPayload.plan_id = product.plan;
      if (product.coupon) {
        paymentIntentPayload.coupon_ids = [product.coupon];
      }
    } else {
      // based on known amount -- note this can break for dynamic tax properties
      paymentIntentPayload.amount = product.totalPrice;
    }
    const requestUrl = process.env.PaymentApiDomain + '/payment-intents';
    try {
      // TODO: change api behaviour for checkout.com
      // giving 200 ok :(
      const response = await axios.post(requestUrl, paymentIntentPayload);
      return {paymentIntent: response.data};
    } catch (error) {
      console.log('Payment intent failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   *
   * @param {*} {
   *     studentId, cardHolder,
   *     coursePicked, ...gatewayParams
   *   }
   * @return {*}
   * @memberof PurchaseUtils
   */
  async createPaymentSource({
    studentId, currency, cardHolder,
    billingAddress, coursePicked, pageKind, ...gatewayParams
  }) {
    if (this.gatewayProcessor.isPaymentIntentSupported()) {
      const product = {
        totalPrice: 0,
        currency: currency,
      };
      const paymentIntentParams = {
        product, studentId, billingAddress, ...gatewayParams,
      };
      const {paymentIntent} =
        await this.createPaymentIntent(paymentIntentParams);
      const authorisedPaymentIntent =
        await this.gatewayProcessor.authorisePaymentIntent(paymentIntent);
      gatewayParams.paymentIntent = authorisedPaymentIntent;
    }
    const paymentSourcePayload = {
      gateway: this.gatewayProcessor.getName(),
      gateway_account_id: this.gatewayProcessor.gatewayAccountId,
      student_id: studentId,
      card_holder_name: cardHolder,
      course_id: coursePicked,
      ...(this.gatewayProcessor.filterPurchaseParams(gatewayParams)),
    };
    const requestUrl = process.env.PaymentApiDomain + '/payment-sources';
    try {
      const response = await axios.post(requestUrl, paymentSourcePayload);
      return {paymentSource: response.data};
    } catch (error) {
      console.log('Payment source failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   *
   * @param {*} {
   *     product, studentId, cardHolder,
   *     billingAddress, coursePicked, ...gatewayParams
   *   }
   * @return {*}
   * @memberof PurchaseUtils
   */
  async purchaseSubscription({
    product, studentId, cardHolder,
    billingAddress, coursePicked, pageKind, paymentSourceType = 'card',
    ...gatewayParams
  }) {
    if (this.gatewayProcessor.isPaymentIntentSupported()) {
      const paymentIntentParams = {
        product, studentId, billingAddress, ...gatewayParams,
      };
      const {paymentIntent} =
        await this.createPaymentIntent(paymentIntentParams);
      const authorisedPaymentIntent =
        await this.gatewayProcessor.authorisePaymentIntent(paymentIntent);
      gatewayParams.paymentIntent = authorisedPaymentIntent;
    }
    const subscriptionPayload = {
      gateway: this.gatewayProcessor.getName(),
      gateway_account_id: this.gatewayProcessor.gatewayAccountId,
      student_id: studentId,
      card_holder_name: cardHolder,
      billing_address: PurchaseUtils.extractBillingAddress(billingAddress),
      course_id: coursePicked,
      payment_source_type: paymentSourceType,
      plan_id: product.plan,
      currency_code: product.currency,
      ...(this.gatewayProcessor.filterPurchaseParams(gatewayParams)),
    };
    if (product.coupon) {
      subscriptionPayload.coupon_ids = [product.coupon];
    }
    const requestUrl = process.env.PaymentApiDomain + '/subscriptions';
    try {
      const response = await axios.post(requestUrl, subscriptionPayload);
      return {subscription: response.data};
    } catch (error) {
      console.log('Purchase subscription failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   *
   * @param {*} {
   *     product, subscription, studentId, cardHolder,
   *     billingAddress, ...gatewayParams
   *   }
   * @return {*}
   * @memberof PurchaseUtils
   */
  async reactivateSubscriptionWithTrial({
    product, subscription, studentId, cardHolder,
    billingAddress, pageKind, ...gatewayParams
  }) {
    if (this.gatewayProcessor.isPaymentIntentSupported()) {
      // create payment intent for 0 price
      // TODO: expose custom action support on api
      const mockedProduct = {
        totalPrice: 0,
        currency: product.currency,
      };
      const paymentIntentParams = {
        product: mockedProduct, studentId, billingAddress, ...gatewayParams,
      };
      const {paymentIntent} =
        await this.createPaymentIntent(paymentIntentParams);
      const authorisedPaymentIntent =
        await this.gatewayProcessor.authorisePaymentIntent(paymentIntent);
      gatewayParams.paymentIntent = authorisedPaymentIntent;
    }
    const reactivationPayload = {
      gateway: this.gatewayProcessor.getName(),
      gateway_account_id: this.gatewayProcessor.gatewayAccountId,
      student_id: studentId,
      card_holder_name: cardHolder,
      billing_address: PurchaseUtils.extractBillingAddress(billingAddress),
      plan_id: product.plan,
      ...(this.gatewayProcessor.filterPurchaseParams(gatewayParams)),
    };
    if (product.coupon) {
      reactivationPayload.coupon_ids = [product.coupon];
    }
    const requestUrl =
      process.env.PaymentApiDomain + '/subscriptions/' +
      subscription.id + '/reactivate';
    try {
      const response = await axios.post(requestUrl, reactivationPayload);
      return {subscription: response.data};
    } catch (error) {
      console.log('Subscription reactivation failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   *
   * @static
   * @param {*} {subscription}
   * @return {*}
   * @memberof PurchaseUtils
   */
  static async oneClickReactivateSubscription(
      {studentId, subscription, pageKind}) {
    const data = {
      student_id: studentId,
    };
    const requestUrl =
        process.env.PaymentApiDomain + '/subscriptions/' +
        subscription.id + '/reactivate';
    try {
      const response = await axios.post(requestUrl, data);
      return {subscription: response.data};
    } catch (error) {
      console.log('Subscription reactivation failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   * TODO: REFACTOR to generic addon purchase
   * @param {*} {
   *     product,studentId
   *   }
   * @return {*}
   * @memberof PurchaseUtils
   */
  static async oneClickPreauthPurchase(
      {studentId, product, pageKind}) {
    const addonId = PRE_AUTH_ADDON_PREFIX + '_' +
      PRE_AUTH_ADDON_VERSION + '_' +
      product.billingCountry;
    const data = {
      student_id: studentId,
      addons: [{
        id: addonId,
        quantity: 1,
        allow_duplicate: true,
      }],
      currency_code: product.currency,
    };
    const requestUrl =
      process.env.PaymentApiDomain + '/invoice-addons';
    try {
      const response = await axios.post(requestUrl, data);
      return {preauth: response.data};
    } catch (error) {
      console.log('preauth purchase failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    };
  };

  /**
   *
   *
   * @static
   * @param {*} gateway
   * @param {*} gatewayAccountId
   * @param {*} pageKind
   * @return {*}
   * @memberof PurchaseUtils
   */
  static async fetchGatewayToken(gateway, gatewayAccountId, pageKind) {
    const data = {
      gateway,
      gateway_account_id: gatewayAccountId,
    };
    const requestUrl = process.env.PaymentApiDomain + '/client-tokens/';
    try {
      const response = await axios.post(requestUrl, data);
      return response.data; // not so REST
    } catch (error) {
      console.log('Gateway token fetch failed', error.message);
      PurchaseUtils.throwApiError({error, pageKind});
    }
  }

  /**
   *
   *
   * @static
   * @param {*} billingAddress
   * @return {*}
   * @memberof PurchaseUtils
   */
  static extractBillingAddress(billingAddress) {
    const extractedBillingAddress = {
      country: billingAddress.country,
    };
    if (billingAddress.zip) {
      extractedBillingAddress.zip = billingAddress.zip;
    }
    if (billingAddress.stateCode) {
      extractedBillingAddress.state_code = billingAddress.stateCode;
    }
    if (billingAddress.billing_address) {
      extractedBillingAddress.country =
      billingAddress?.countryCode.toUpperCase();
      extractedBillingAddress.accountHolderName =
      billingAddress?.accountHolderName;
      extractedBillingAddress.email = billingAddress?.email;
      extractedBillingAddress.line1 = billingAddress?.billing_address;
      extractedBillingAddress.city = billingAddress?.city;
      // extractedBillingAddress.zip = billingAddress?.postalCode;
    }
    return extractedBillingAddress;
  }

  /**
   *
   *
   * @static
   * @param {*} error
   * @param {*} [type=Error.TYPES.PAYMENT_API_ERROR]
   * @memberof PurchaseUtils
   */
  static throwApiError(
      {error, type = Error.TYPES.PAYMENT_API_ERROR, pageKind}) {
    const updatedErrorPointers = {};
    let errorMessage = 'Something went wrong, try again';
    let nextAction = Error.NEXT_ACTIONS.UNKNOWN;
    // axios based error handler
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      if ([401, 403].includes(error.response.status)) {
        // AUTH ERRORS
        errorMessage = 'Kindly login to continue!';
        nextAction = Error.NEXT_ACTIONS.REDIRECT_TO_LOGIN;
      } else if (error.response.status === 429) {
        // RATE LIMITING ERRORS
        errorMessage = 'Too many requests. Please try after sometime.';
        nextAction = Error.NEXT_ACTIONS.REFRESH_AND_RETRY;
      } else if (Array.isArray(error.response.data?.errors)) {
        for (const error of error.response.data.errors) {
          // WARN: errors without proper pointers will get overwritten
          updatedErrorPointers[error.source?.pointer || '_all'] = error.title;
          errorMessage = error.title;
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      //  and an instance of http.ClientRequest in node.js
      errorMessage =
        'Something went wrong. Kindly refresh the page & try again...';
      nextAction = Error.NEXT_ACTIONS.REFRESH_AND_RETRY;
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage =
        'Something went wrong. Kindly refresh the page & try again...';
      nextAction = Error.NEXT_ACTIONS.REFRESH_AND_RETRY;
    }
    Utils.triggerApiErrorAction(
        pageKind, error.config?.url, error.response?.status);
    throw new Error({
      type,
      message: errorMessage,
      nextAction: nextAction,
      errorPointers: updatedErrorPointers,
      sourceError: error,
    });
  }
}

export default PurchaseUtils;
