import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import add from 'date-fns/add';
import toDate from 'date-fns/toDate';
import LazyLoad from 'react-lazyload';
import {KeyValuePair} from '../Application/Shared';
import SplitGatewayMapping from './Data/SplitGatewayMapping';
import GatewayDetails from './Data/GatewayDetails';
import GatewayRetryMapping from './Data/GatewayRetryMapping';
// import StripeCardPayment from './Gateways/StripeCardPayment';
import CheckoutCardPayment from './Gateways/CheckoutCardPayment';
import ChargebeeCardPayment from './Gateways/ChargebeeCardPayment';
// import AdyenCardPayment from './Gateways/AdyenCardPayment';
import CardPaymentPlaceHolder from './CardPaymentPlaceholder';
import Error from './Utils/Error';
import {redirectWithDelay} from './Utils';

const SC_1M_FT_COOKIE_PREFIX = 'sc-1m-ft';

const isRetryForSameGateway = (cardProcessor, student) => {
  if (
    cookie.load(`${
      SC_1M_FT_COOKIE_PREFIX
    }_${student.id}_${
      cardProcessor.chargebee_gateway_id
    }`,
    )) {
    return true;
  } else {
    return false;
  }
};

const getCardProcessor = (student) => {
  const {country, region, city} = student.attributes.address;
  const cityDefaultChargebeeGatewayId =
    SplitGatewayMapping[country]?.[region]?.[city]?.['default']?.
        ['chargebee_gateway_id'];
  const stateDefaultChargebeeGatewayId =
    SplitGatewayMapping[country]?.[region]?.['default']?.
        ['chargebee_gateway_id'];
  const countryDefaultChargebeeGatewayId =
    SplitGatewayMapping[country]?.['default']?.['chargebee_gateway_id'];
  const globalDefaultChargebeeGatewayId =
    SplitGatewayMapping['default']?.['chargebee_gateway_id'];
  const chargebeeGatewayId =
    cityDefaultChargebeeGatewayId ||
    stateDefaultChargebeeGatewayId ||
    countryDefaultChargebeeGatewayId ||
    globalDefaultChargebeeGatewayId;
  const cardProcessor = {
    gateway: '',
    gateway_public_key: '',
    chargebee_gateway_id: chargebeeGatewayId,
  };
  Object.assign(cardProcessor, GatewayDetails[chargebeeGatewayId]);
  if (isRetryForSameGateway(cardProcessor, student)) {
    throw new Error({
      type: Error.TYPES.INTERNAL_ERROR,
      message: 'Gateway re-attempt',
      nextAction: Error.NEXT_ACTIONS.UNKNOWN,
      errorPointers: {
        '_all': 'Gateway re-attempt',
      },
    });
  }
  return cardProcessor;
};

const checkZipCodeInputEligibility = (student) => {
  if (['us', 'ca'].includes(student?.attributes.address.country)) {
    return true;
  }
  return false;
};

const checkStateCodeInputEligibility = (student) => {
  if (['us'].includes(student?.attributes.address.country)) {
    return true;
  }
  return false;
};

const CardPayment = ({
  pageData,
  pageOfferTypeTexts,
  billingConsent,
  setLoadingState,
  handleError,
  onPurchaseSuccess, onReactivationSuccess,
  student, product, subscription, language,
}) => {
  // state hooks
  const [cardProcessor, setCardProcessor] = useState({
    gateway: '',
    gateway_public_key: '',
    chargebee_gateway_id: '',
  });
  const [cardMeta, setCardMeta] = useState({});

  // effect hooks
  useEffect(() => {
    if (student) {
      try {
        const updatedCardProcessor =
          getCardProcessor(student);
        setCardProcessor(updatedCardProcessor);
      } catch (error) {
        retryWithGatewaySequence(error);
      }
    }
  }, [student]);

  const showZipcodeInput = checkZipCodeInputEligibility(student);
  const showStateCodeInput = checkStateCodeInputEligibility(student);

  const isEligibleForRetryWithGatewaySeq = (error) => {
    const errorMessage =
      error.getFirstErrorMessage ? error.getFirstErrorMessage() : error.message;
    const errorCode = error.sourceError?.response?.data?.meta?.decline_code ||
      error.sourceError?.response?.code;
    // ignore local validation errors
    if (error.type === Error.TYPES.VALIDATION_ERROR) {
      return false;
    }
    // ignore if not present in seq
    if (!(GatewayRetryMapping[student?.attributes.address.country] ||
      GatewayRetryMapping['default'])) {
      return false;
    }
    if (cardProcessor.gateway === 'checkout_com') {
      if ([
        'Transaction not Permitted to Cardholder',
        'Declined - Do Not Honour',
      ].includes(errorMessage)) {
        return true;
      } else if ((errorCode < 50000) && (errorCode >= 20000)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const retryWithGatewaySequence = (error) => {
    const proceedWithRetrySeq = isEligibleForRetryWithGatewaySeq(error);
    if (proceedWithRetrySeq) {
      const retryEntriesForCountry =
        GatewayRetryMapping[student?.attributes.address.country] ||
        GatewayRetryMapping['default'];
      const currentIndex =
        retryEntriesForCountry.indexOf(cardProcessor.chargebee_gateway_id);
      if (retryEntriesForCountry[currentIndex+1]) {
        // TODO: use card processor switching
        setLoadingState(`We encountered an issue, please try again`);
        const languagePath = language==='en'?'':language+'/';
        const paymentURL=`${process.env.PaymentWebDomain}/${languagePath}` +
        `payment/subscription/?product_code=sc-1m-ft&chargebee_gateway_id=`+
        `${retryEntriesForCountry[currentIndex+1]}`;
        redirectWithDelay(paymentURL, 1000);
      } else {
        // no more options available
        // TODO -- loader won't work on first load.. something else dismissing
        setLoadingState(
            `Sorry, we couldn't complete your request. Try again`,
        );
        const languagePath = language==='en'?'':language+'/';
        let paymentURL=`${process.env.PaymentWebDomain}/${languagePath}` +
          `payment/lifetime-membership/?coupon=lifetime49`;
        if (student?.attributes.address.country === 'za') {
          paymentURL = `${process.env.PaymentWebDomain}/${languagePath}` +
            'za/' + 'payment/subscription/?product_code=sc-1m-ft';
        }
        redirectWithDelay(paymentURL, 1000);
      }
      // write if not present
      if (!cookie.load(`${
        SC_1M_FT_COOKIE_PREFIX
      }_${student.id}_${
        cardProcessor.chargebee_gateway_id
      }`,
      )) {
        const expires = toDate(add(new Date(), {hours: 24}));
        cookie.save(
            `${SC_1M_FT_COOKIE_PREFIX
            }_${student.id}_${cardProcessor.chargebee_gateway_id}`,
            'true',
            {path: '/', domain: process.env.CookieDomain, expires});
      }
    }
    return proceedWithRetrySeq;
  };

  return (
    <>
      {/*
        cardProcessor.gateway === 'stripe' &&
        <StripeCardPayment
          pageData={pageData}
          pageLabels={pageLabels}
          errorLabels={errorLabels}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          showZipcodeInput={showZipcodeInput}
          showStateCodeInput={showStateCodeInput}
          setLoadingState={setLoadingState}
          handleError={handleError}
          cardMeta={cardMeta}
          setCardMeta={setCardMeta}
          onPurchaseSuccess={onPurchaseSuccess}
          onReactivationSuccess={onReactivationSuccess}
          student={student}
          product={product}
          subscription={subscription}
          language={language}
          cardProcessor={cardProcessor}
        /> */
      }
      {
        cardProcessor.gateway === 'checkout_com' &&
        <LazyLoad height={780} offset={-200} once={true}>
          <CheckoutCardPayment
            pageData={pageData}
            pageOfferTypeTexts={pageOfferTypeTexts}
            billingConsent={billingConsent}
            showZipcodeInput={showZipcodeInput}
            showStateCodeInput={showStateCodeInput}
            setLoadingState={setLoadingState}
            handleError={handleError}
            cardMeta={cardMeta}
            setCardMeta={setCardMeta}
            onPurchaseSuccess={onPurchaseSuccess}
            onReactivationSuccess={onReactivationSuccess}
            student={student}
            product={product}
            subscription={subscription}
            language={language}
            cardProcessor={cardProcessor}
            retryWithGatewaySequence={retryWithGatewaySequence}
          />
        </LazyLoad>

      }
      {/* {
        cardProcessor.gateway === 'adyen' &&
        <AdyenCardPayment
          pageData={pageData}
          pageLabels={pageLabels}
          errorLabels={errorLabels}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          showZipcodeInput={showZipcodeInput}
          showStateCodeInput={showStateCodeInput}
          setLoadingState={setLoadingState}
          handleError={handleError}
          cardMeta={cardMeta}
          setCardMeta={setCardMeta}
          onPurchaseSuccess={onPurchaseSuccess}
          onReactivationSuccess={onReactivationSuccess}
          student={student}
          product={product}
          subscription={subscription}
          language={language}
          cardProcessor={cardProcessor}
          retryWithGatewaySequence={retryWithGatewaySequence}
        />
      } */}
      {
        cardProcessor.gateway === 'chargebee' &&
        <LazyLoad height={780} offset={-200} once={true}>
          <ChargebeeCardPayment
            pageData={pageData}
            pageOfferTypeTexts={pageOfferTypeTexts}
            billingConsent={billingConsent}
            showZipcodeInput={showZipcodeInput}
            showStateCodeInput={showStateCodeInput}
            setLoadingState={setLoadingState}
            handleError={handleError}
            cardMeta={cardMeta}
            setCardMeta={setCardMeta}
            onPurchaseSuccess={onPurchaseSuccess}
            onReactivationSuccess={onReactivationSuccess}
            student={student}
            product={product}
            subscription={subscription}
            language={language}
            cardProcessor={cardProcessor}
            retryWithGatewaySequence={retryWithGatewaySequence}
          />
        </LazyLoad>
      }
      {
        !cardProcessor.gateway &&
        <CardPaymentPlaceHolder
          pageData={pageData}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          showZipcodeInput={showZipcodeInput}
          product={product}
        />
      }
    </>
  );
};

CardPayment.propTypes = {
  pageData: PropTypes.object.isRequired,
  pageOfferTypeTexts: PropTypes.instanceOf(KeyValuePair).isRequired,
  billingConsent: PropTypes.node.isRequired,
  setLoadingState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  onPurchaseSuccess: PropTypes.func.isRequired,
  onReactivationSuccess: PropTypes.func.isRequired,
  student: PropTypes.object,
  product: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default CardPayment;
