import React from 'react';
import PropTypes from 'prop-types';
import styles from './payments.module.scss';


//  TODO: move css included from parent :|

const FAQSection = ({title, questions, isForMobile}) => {
  return (
    <div
      className =
        {`${styles.faqsSection} ${isForMobile ? styles.showOnMobile :
          styles.hideOnMobile }`}
    >
      <div className={styles.header}>
        {title}
      </div>
      {questions.map((faq, index) => (
        <div className={styles.content} key={index}>
          <div className={styles.question}>
            <span>{faq.question}</span>
          </div>
          <div className={styles.answer}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

FAQSection.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  isForMobile: PropTypes.bool.isRequired,
};

export default FAQSection;
