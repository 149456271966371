import GatewayProcessor from './GatewayProcessor';
import Error from '../../Utils/Error';

/**
 *
 *
 * @class CheckoutProcessor
 */
class CheckoutProcessor extends GatewayProcessor {
  /**
   * Creates an instance of CheckoutProcessor.
   * @param {*} chargebeeGatewayId
   * @param {*} iFrameRef
   * @memberof CheckoutProcessor
   */
  constructor(chargebeeGatewayId, iFrameRef) {
    super('checkout_com');
    this.gatewayAccountId = chargebeeGatewayId;
    this.iFrameRef = iFrameRef;
  }

  /**
   *
   *
   * @return {*}
   * @memberof CheckoutProcessor
   */
  isPaymentIntentSupported() {
    return true;
  }

  /**
   *
   *
   * @param {*} {cardToken}
   * @return {*}
   * @memberof CheckoutProcessor
   */
  filterPaymentIntentParams({cardToken}) {
    return {
      card_token: cardToken,
    };
  }

  /**
   *
   *
   * @param {*} paymentIntent
   * @return {*}
   * @memberof CheckoutProcessor
   */
  async authorisePaymentIntent(paymentIntent) {
    if (paymentIntent.status === 'Card Verified' &&
      paymentIntent.approved === true) {
      return paymentIntent;
    } else if (paymentIntent.status === 'Authorized') {
      return paymentIntent;
    } else if (paymentIntent.status === 'Pending') {
      await this._authoriseWithIframe(paymentIntent._links.redirect.href,
          this.iFrameRef);
      return paymentIntent;
    } else {
      // TODO: handle 3DS
      // TODO: remove hack
      const updatedErrorPointers = {};
      const updateSourceError = {};
      if (Array.isArray(paymentIntent.errors)) {
        for (const error of paymentIntent.errors) {
          // WARN: errors without proper pointers will get overwritten
          updatedErrorPointers[error.source?.pointer || '_all'] = error.title;
        }
      }
      if (Object.keys(updatedErrorPointers).length === 0) {
        updatedErrorPointers['_all'] = 'Card Declined';
      }
      if (paymentIntent && paymentIntent.meta) {
        updateSourceError['response'] = paymentIntent.meta;
      }
      throw new Error({
        type: Error.TYPES.INTERNAL_ERROR,
        message: 'Payment Intent check failed',
        nextAction: Error.NEXT_ACTIONS.UNKNOWN,
        errorPointers: updatedErrorPointers,
        sourceError: updateSourceError,
      });
    }
  }

  /**
   *
   *
   * @param {*} {paymentIntent}
   * @return {*}
   * @memberof CheckoutProcessor
   */
  filterPurchaseParams({paymentIntent}) {
    return {
      gw_token: paymentIntent.id,
    };
  }
  /**
   *
   *
   * @param {*} redirectUrl
   * @param {*} iFrameRef
   * @param {*} setLoadingState
   * @return {*}
   * @memberof CheckoutProcessor
   */
  _authoriseWithIframe(redirectUrl, iFrameRef) {
    return new Promise((resolve, reject) => {
      window.addEventListener('message', function(e) {
        if (e.data.closePage) {
          iFrameRef.current.style.display = 'none';
          resolve();
        }
      }, false);
      iFrameRef.current.setAttribute('src', redirectUrl);
      iFrameRef.current.style.display = 'block';
    });
  }
}

export default CheckoutProcessor;
