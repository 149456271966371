import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import format from 'date-fns/format';
import {
  getFormattedPrice, getTrialEndDate,
  getElementRefByClassName,
} from './Utils';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import styles from './payments.module.scss';


const BillingConsent = (props) => {
  const {getFreeTrialPeriodText, product, language} = props;
  const {data, showTaxDisclaimer,
    getBillingPeriodText} = props;
  const consentBlock = data.consent;
  const taxDisclaimerText = data.statetaxdisclaimer;
  const termsRef = useRef(null);
  const locale={
    en,
    de,
    fr,
    it,
    es,
    pt,
  };

  useEffect(() => {
    const freeTrialPeriodRef =
      getElementRefByClassName(termsRef, 'free-trial-period');
    const subChargeRef =
      getElementRefByClassName(termsRef, 'subscription-charge');
    const trialEndDateRef =
      getElementRefByClassName(termsRef, 'trial-end-date');
    const billingPeriodRef =
      getElementRefByClassName(termsRef, 'billing-period');

    freeTrialPeriodRef.textContent = getFreeTrialPeriodText();
    subChargeRef.textContent = getFormattedPrice(
        product.totalPrice, product.currency,
        product.currencySymbol,
    );
    const trialEndDate = getTrialEndDate(product);
    trialEndDateRef.textContent = format(trialEndDate, 'MMM, dd, yyyy', {
      locale: locale[language],
    });

    billingPeriodRef.textContent = getBillingPeriodText();
  }, [product, showTaxDisclaimer]);

  return (
    <div className={styles.terms} ref={termsRef}>
      {consentBlock && htmr(consentBlock)}
      { showTaxDisclaimer &&
        <p className={styles.desc}>
          {taxDisclaimerText}
        </p>
      }
    </div>
  );
};

BillingConsent.propTypes = {
  getFreeTrialPeriodText: PropTypes.func.isRequired,
  getBillingPeriodText: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  showTaxDisclaimer: PropTypes.bool.isRequired,
};

export default BillingConsent;
