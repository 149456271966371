import React, {useState, useRef, useEffect} from 'react';
import {Card, ListGroup} from 'react-bootstrap';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import {getElementRefByClassName} from './Utils';
import format from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import AlertTimer from '../../static/images/payment/alert-timer.inline.svg';
import styles from './payments.module.scss';
import GreenBell from '../../static/images/payment/green-bell.inline.svg';
import GreenTick from '../../static/images/payment/green-tick.inline.svg';


/**
 *
 *
 * @return {*}
 */

const ProductCard = (props) => {
  const {getFreeTrialPeriodText, getFormattedPrice, product,
    getTrialEndDate, data, showTaxDisclaimer, language, showPlanSwitcher,
    student, setActiveBillingFrequency, activeBillingFrequency} = props;
  const productCardRef = useRef(null);
  const [progress, setProgress] = useState(0);
  // const [showBenefits, setShowBenefits] = useState(false);
  const benefitsRef=useRef(null);
  const [showCountdownTimer, toggleCountdownTimer] = useState(true);
  const [timer, setTimer] = useState(Date.now() + (10 * 60 * 1000));

  const locale={
    en,
    de,
    fr,
    it,
    es,
    pt,
  };


  const CountdownRenderer = (timerProp) => {
    if (timerProp.completed) {
      toggleCountdownTimer(false);
      return <></>;
    } else {
      return <div className={styles.customCountdown}>
        {timerProp.formatted.minutes}:{timerProp.formatted.seconds}
      </div>;
    }
  };

  const triggerCountdownTimer = () => {
    toggleCountdownTimer(true);
    setTimer(Date.now() + (10 * 60 * 1000));
  };

  // const triggerBenefits = () => {
  //   let displayProp=benefitsRef.current.style.display;
  //   if (displayProp === 'block') {
  //     displayProp='none';
  //   } else {
  //     displayProp='block';
  //   }
  //   benefitsRef.current.style.display=displayProp;
  // };

  useEffect(() => {
    let capacityElement=0;
    const percentage = setInterval(() => {
      if (capacityElement==68) {
        clearInterval(percentage);
      }
      setProgress(capacityElement++);
    }, 20);
    benefitsRef.current.style.display='block';
  }, []);
  const country = student?.attributes?.address?.country;

  useEffect(() => {
    const subPeriodRef =
      getElementRefByClassName(productCardRef, 'sub_period');
    const actualPriceRef =
      getElementRefByClassName(productCardRef, 'actual-charge');
    const subChargeRef =
      getElementRefByClassName(productCardRef, 'subscription-charge');
    const textDiscalimerRef =
      getElementRefByClassName(productCardRef, 'tax-discalimer');
    const billingPeriodUnitRef =
      getElementRefByClassName(productCardRef, 'billing-period-unit');
    subPeriodRef.textContent = getFreeTrialPeriodText();
    subChargeRef.textContent = getFormattedPrice(product.totalPrice,
        product.currency, product.currencySymbol);
    if (actualPriceRef && product.discountPercentage) {
      actualPriceRef.textContent = getFormattedPrice(product.price,
          product.currency, product.currencySymbol);
    } else {
      actualPriceRef.textContent ='';
    }
    if (!showTaxDisclaimer) {
      textDiscalimerRef.style.display = 'none';
    }
    if (activeBillingFrequency === 1) {
      billingPeriodUnitRef.textContent = `month`;
    } else if (activeBillingFrequency === 12) {
      billingPeriodUnitRef.textContent = `year`;
    }

    const trialEndDateRef =
      getElementRefByClassName(productCardRef, 'trial-end-date');
    const trialEndDate = getTrialEndDate();
    trialEndDateRef.textContent = format(trialEndDate, 'MMM, dd, yyyy', {
      locale: locale[language],
    });
    const currencySymbolRef =
      getElementRefByClassName(productCardRef, 'currency-code');
    currencySymbolRef.textContent = product.currencySymbol;
  }, [product, showTaxDisclaimer]);

  // const firstName = (student) ?
  //   student.attributes.name.first_name : '';
  return (
    <Card className={styles.productDetails} ref={productCardRef}>
      <Card.Header>
        {htmr(data.heading)}
        {/* <div className={styles.help}
          onClick={() => triggerBenefits()}>
          ?
        </div> */}
      </Card.Header>
      <Card.Body>
        {/* {(['au', 'za', 'ca'].
        // includes(student?.attributes.address.country)) ?
        <div className={styles.studentName}>
          <span>{`Welcome ${firstName}!`}</span>
        </div>:''
        } */}
        <div ref={benefitsRef}>
          <ListGroup variant="flush">
            {data.list.map((listItem, index) => (
              <ListGroup.Item key={index}>
                <div className={styles.imgWrapper}>
                  {data.list.length===index+2?
                <GreenBell />:<GreenTick />}
                </div>
                {htmr(listItem.item)}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        {showPlanSwitcher&& <div className={styles.planSwitcherSection}>
          <p className={`${styles.planType} ${
              activeBillingFrequency===1?styles.active: ''}`}>
            Pay Monthly
          </p>
          <div id={`planSwitcher`} className={`${styles.planToggler} ${
                activeBillingFrequency===1? styles.monthly: ''
          }`} onClick={() =>
            setActiveBillingFrequency(activeBillingFrequency===12?1:12)}>
            <div className={styles.button}></div>
          </div>
          <p className={`${styles.planType} ${
                activeBillingFrequency===12?styles.active: ''}`}>
            Pay Yearly
          </p>
          <img src="/forms/arrow.svg" />
          <span className={styles.offerBenefitLabel}>
            save 75%
          </span>
        </div>}
        <div className={`${country === 'us' ?
        styles.reduceMargin : ''} ${styles.alertContainer}`}>
          <div className={styles.alertProgressBar}
            style={{'width': `${progress}%`}}>
          </div>
          <div className={styles.alertContent}>
            <div className={styles.alertTopSection}>
              <AlertTimer />
              <p>{progress}% of this week&lsquo;s places have been filled</p>
            </div>
            <p className={styles.alertSectionInfo}>
                Start your course now to guarantee your place
            </p>
          </div>
          {showCountdownTimer && <div className={styles.customCountdownWrapper}>
            <p>Your place has been reserved for</p>
            <Countdown
              date={timer}
              zeroPadTime={2}
              renderer={CountdownRenderer}/>
          </div>}
          {!showCountdownTimer &&
          <div className={styles.customRereserveWrapper}>
            <p>Would you like to re-reserve your place?</p>
            <div className={styles.customRereserveButton}
              onClick={triggerCountdownTimer}
            >Yes</div>
          </div>}
        </div>
      </Card.Body>
    </Card>
  );
};

ProductCard.propTypes = {
  getFreeTrialPeriodText: PropTypes.func.isRequired,
  getFormattedPrice: PropTypes.func.isRequired,
  getTrialEndDate: PropTypes.func.isRequired,
  setActiveBillingFrequency: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  showTaxDisclaimer: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  student: PropTypes.object,
  activeBillingFrequency: PropTypes.number,
  showPlanSwitcher: PropTypes.bool,
};

export default ProductCard;
