export const PriceMapping = {
  'ae': {
    'currency_code': 'aed',
    'cur_symbol': 'AED',
    'price': 159.99,
  },
  'au': {
    'currency_code': 'aud',
    'cur_symbol': 'AUD $',
    'price': 69.99,
  },
  'ca': {
    'currency_code': 'cad',
    'cur_symbol': 'CAD $',
    'price': 69.99,
  },
  'eg': {
    'currency_code': 'egp',
    'cur_symbol': 'EGP',
    'price': 249,
  },
  'eu': {
    'currency_code': 'eur',
    'cur_symbol': '€',
    'price': 49.99,
  },
  'gb': {
    'currency_code': 'gbp',
    'cur_symbol': '£',
    'price': 49.99,
  },
  'in': {
    'currency_code': 'inr',
    'cur_symbol': '₹',
    'price': 600,
  },
  'ke': {
    'currency_code': 'kes',
    'cur_symbol': 'KES',
    'price': 1699,
  },
  'lk': {
    'currency_code': 'lkr',
    'cur_symbol': 'LKR',
    'price': 1849,
  },
  'ma': {
    'currency_code': 'mad',
    'cur_symbol': 'MAD',
    'price': 179,
  },
  'mv': {
    'currency_code': 'mvr',
    'cur_symbol': 'MVR',
    'price': 429,
  },
  'my': {
    'currency_code': 'myr',
    'cur_symbol': 'RM',
    'price': 60,
  },
  'ng': {
    'currency_code': 'ngn',
    'cur_symbol': '₦',
    'price': 5499,
  },
  'np': {
    'currency_code': 'npr',
    'cur_symbol': 'NPR',
    'price': 1199,
  },
  'nz': {
    'currency_code': 'nzd',
    'cur_symbol': 'NZD $',
    'price': 69.99,
  },
  'ph': {
    'currency_code': 'php',
    'cur_symbol': '₱',
    'price': 500,
  },
  'pk': {
    'currency_code': 'pkr',
    'cur_symbol': 'PKR',
    'price': 1349,
  },
  'qa': {
    'currency_code': 'qar',
    'cur_symbol': 'QAR',
    'price': 99.99,
  },
  'sa': {
    'currency_code': 'sar',
    'cur_symbol': 'SAR',
    'price': 69.99,
  },
  'sg': {
    'currency_code': 'sgd',
    'cur_symbol': 'SGD $',
    'price': 39.99,
  },
  'tr': {
    'currency_code': 'try',
    'cur_symbol': 'TRY',
    'price': 69.99,
  },
  'tz': {
    'currency_code': 'tzs',
    'cur_symbol': 'TZS',
    'price': 27499,
  },
  'ug': {
    'currency_code': 'ugx',
    'cur_symbol': 'UGX',
    'price': 58999,
  },
  'us': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 69.99,
  },
  'za': {
    'currency_code': 'zar',
    'cur_symbol': 'ZAR',
    'price': 399,
  },
  '3w': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 10,
  },
  'default': {
    'currency_code': 'usd',
    'cur_symbol': '$',
    'price': 69.99,
  },
};

export const PlanDetails = {
  1: {
    billingPeriod: 4,
    billingPeriodUnit: 'weeks',
  },
  12: {
    billingPeriod: 1,
    billingPeriodUnit: 'year',
  },
};
