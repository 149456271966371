/**
 *
 * Useful for detecting gateway specific purchase flow
 * Filter methods help extract relevant gateway specific params
 * Authorisation method helps to complete gateway specifc payment auth logic
 *
 * @class GatewayProcessor
 */
class GatewayProcessor {
  /**
   * Creates an instance of GatewayProcessor.
   * @param {*} name
   * @memberof GatewayProcessor
   */
  constructor(name) {
    this.name = name;
  }

  /**
   *
   *
   * @return {*}
   * @memberof GatewayProcessor
   */
  getName() {
    return this.name;
  }

  /**
   *
   *
   * @return {*}
   * @memberof GatewayProcessor
   */
  isPaymentIntentSupported() {
    return true;
  }

  /**
   *
   *
   * @param {*} params
   * @return {*}
   * @memberof GatewayProcessor
   */
  filterPaymentIntentParams({}) {
    return {};
  }

  /**
   *
   *
   * @param {*} paymentIntent
   * @return {*}
   * @memberof GatewayProcessor
   */
  async authorisePaymentIntent(paymentIntent) {
    return paymentIntent;
  }

  /**
   *
   *
   * @param {*} {}
   * @return {*}
   * @memberof GatewayProcessor
   */
  filterPurchaseParams({}) {
    return {};
  }
}

export default GatewayProcessor;
