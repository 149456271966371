/**
 *
 * Error class with exhaustive details including next actions
 * Useful for propagating detailed info across
 * @class Error
 */
class Error {
  /**
   * Creates an instance of Error.
   * @param {*} {type, message, nextAction, paramErrors}
   * @memberof Error
   */
  constructor({type, message, nextAction, errorPointers, sourceError}) {
    this.type = type;
    this.message = message;
    this.nextAction = nextAction;
    this.errorPointers = errorPointers || {};
    this.sourceError = sourceError;
  }

  /**
   * Extracts and returns first error from errorPointers
   * with fallback to top level error message
   * @return {*}
   * @memberof Error
   */
  getFirstErrorMessage() {
    let errorMessage = this.message;
    for (const errorField in this.errorPointers) {
      if (this.errorPointers.hasOwnProperty(errorField)) {
        errorMessage = this.errorPointers[errorField];
        break;
      }
    }
    return errorMessage;
  }

  /**
   *
   *
   * @return {*}
   * @memberof Error
   */
  getFirstErrorField() {
    for (const errorField in this.errorPointers) {
      if (this.errorPointers.hasOwnProperty(errorField)) {
        return errorField;
      }
    }
    return null;
  }

  static TYPES = {
    API_ERROR: 'api_error',
    PAYMENT_API_ERROR: 'payment_api_error',
    STRIPE_SDK_ERROR: 'stripe_sdk_error',
    CHECKOUT_SDK_ERROR: 'checkout_sdk_error',
    INTERNAL_ERROR: 'internal_error',
    VALIDATION_ERROR: 'validation_error',
  }

  static NEXT_ACTIONS = {
    // Logout & redirect to login with current url
    REDIRECT_TO_LOGIN: 'redirect_to_login',
    // redirect to home
    REDIRECT_TO_HOME: 'redirect_to_home',
    // Refresh the page to retry
    REFRESH_AND_RETRY: 'refresh_and_retry',
    // To be evaluated by the receiver
    UNKNOWN: 'unknown',
  }
}

export default Error;
