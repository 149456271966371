export const PRE_AUTH_ADDON_PREFIX = 'pre-auth-charges';

export const PRE_AUTH_ADDON_VERSION = 'v8';

export const PRE_AUTH_CHARGE_DATA = {};

export const isPreAuthPresent = (countryCode) => {
  if (PRE_AUTH_CHARGE_DATA[countryCode]) {
    return true;
  } else {
    return false;
  }
};
