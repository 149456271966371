import React from 'react';
// import loadable from '@loadable/component';
// import {Helmet} from 'react-helmet';
import SubscriptionPage from './SubscriptionPage';
// const SubscriptionPage=loadable(() => import('./SubscriptionPage'));
import subscriptionPageData from '../../data/subscriptionPlan.json';

/**
 *
 *
 * @return {*}
 */
function SubscriptionPageLite() {
  // const locale = data.shawWebsiteDashboard.locale || 'en';
  return (
    <React.Fragment>
      {/* <Helmet>
        <script defer async src="https://cdn.checkout.com/js/framesv2.min.js" />
      </Helmet> */}
      <SubscriptionPage data={subscriptionPageData.data} />
    </React.Fragment>
  );
}

export default SubscriptionPageLite;
