import React, {useState} from 'react';
import PropTypes from 'prop-types';
import GreenTick from '../../../static/images/payment/green-tick.inline.svg';

/**
 *
 *
 * @return {*}
 */
function PaymentJustification({
  product,
  styles,
}) {
  // TODO: Content from content stack
  const [showPopup, triggerPopup]=useState(false);
  return (
    <div className={styles.paymentJustification}>
      <p className={styles.paymentJustificationTrigger}
        onClick={() => triggerPopup(!showPopup)}>
        Why do we need this for a free trial?
      </p>
      <div className={`${styles.paymentJustificationPopup} ${
        showPopup?styles.show:''}`}>
        <div className={styles.overlay}
          onClick={() => triggerPopup(false)}>
        </div>
        <div className={styles.popupContent}>
          <p>
            <strong>Don&apos;t worry, you won&apos;t be charged
               until your 4 Week trial ends.</strong>
    We ask for payment details to prevent multiple
     free trials for one person.
          </p>
          <p className={styles.popupHeading}>Our promise:</p>
          <ul>
            <li>
              <GreenTick/>
              <div>
            You Pay <strong>
                  <span className={styles.currency}>
                    {product.currencySymbol}
                  </span>0.00</strong> today
              </div>
            </li>
            <li>
              <GreenTick/>
              <div>
              No commitment, <strong>easy cancellation</strong>
              </div>
            </li>
            <li>
              <GreenTick/>
              <div>
              As a reminder, you can track trial periods online
              </div>

            </li>
          </ul>
          <p className={styles.close}
            onClick={() => triggerPopup(!showPopup)}>
            Close
          </p>
        </div>
      </div>
    </div>
  );
}

PaymentJustification.propTypes = {
  product: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

export default PaymentJustification;
