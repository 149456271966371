import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Form, Row} from 'react-bootstrap';
import htmr from 'htmr';

import {KeyValuePair} from '../Application/Shared';
import {getFormattedPrice} from './Utils';
import GreenShield from
  '../../static/images/payment/green-shield.inline.svg';
import styles from './payments.module.scss';
import VisaCardIcon from
  '../../static/images/payment/visa-logo.inline.svg';
import MasterCardIcon from
  '../../static/images/payment/mastercard.inline.svg';
import AmericanExpressIcon from
  '../../static/images/payment/american-express.inline.svg';
import JcbCardIcon from
  '../../static/images/payment/jcb.inline.svg';
import DiscoverCardIcon from
  '../../static/images/payment/discover.inline.svg';

const CardPaymentPlaceHolder = ({
  pageData,
  pageOfferTypeTexts,
  billingConsent,
  product,
}) => {
  let currentOfferTypeText = pageOfferTypeTexts.getValue(product.freeTrialDays);
  if (!currentOfferTypeText) {
    currentOfferTypeText = `4 weeks free`;
  }

  return (
    <Form className="sh-payment-form"
      onSubmit={
        (e) => {
          e.preventDefault();
        }
      }>
      <Form.Group controlId="cardNumber">
        <Form.Label>
          Card Number
        </Form.Label>
        <div className={styles.paymentMethodLogos}>
          <VisaCardIcon />
          <MasterCardIcon />
          <AmericanExpressIcon />
          <DiscoverCardIcon />
          <JcbCardIcon/>
        </div>
        <div className="sh-input"/>
      </Form.Group>
      <Row>
        <Form.Group controlId="cardExpiry">
          <Form.Label className="card-expiry">
            EXPIRY DATE
          </Form.Label>
          <div className="sh-input"/>
        </Form.Group>
        <Form.Group controlId="cardCvc">
          <Form.Label>
            CVC/CVV
          </Form.Label>
          <div className="sh-input"/>
        </Form.Group>
      </Row>
      <Form.Group controlId="cardName">
        <Form.Label>
          NAME
        </Form.Label>
        <Form.Control
          className="sh-input"
          type="text"
          name="name"
          placeholder={`CARDHOLDER NAME`}
          autoComplete="off"
          required={true}
        />
      </Form.Group>
      <Card className={styles.ctaSection}>
        <div className={styles.topSection}>
          <p className={styles.price}>
            Today&apos;s total :&nbsp;
            {product.freeTrialPeriod ? getFormattedPrice(0,
                product.currency, product.currencySymbol) :
                  getFormattedPrice(product.totalPrice,
                      product.currency, product.currencySymbol)}
          </p>
          <p className={styles.weeks}>
            <span>{currentOfferTypeText}</span>
            <span>•</span>
            <span>Cancel at any time</span>
          </p>
        </div>
        {/* TODO : Make the text dynamic */}

        <Button type="submit" className={styles.payBtn}>
          {htmr(pageData.paymentsection.cta)}
        </Button>
        <div className={styles.encryptionWrapper}>
          <p className={styles.text}>
            Secure payments
          </p>
          <div className={styles.logo}>
            <GreenShield />
            <p>
              128-bit SSL Encryption
            </p>
          </div>
        </div>
      </Card>
      {billingConsent}
      <div className={styles.subConsentText}>
        {htmr(pageData.paymentsection.contactinfo)}
      </div>
    </Form>
  );
};

CardPaymentPlaceHolder.propTypes = {
  pageData: PropTypes.object.isRequired,
  pageOfferTypeTexts: PropTypes.instanceOf(KeyValuePair).isRequired,
  billingConsent: PropTypes.node.isRequired,
  product: PropTypes.object.isRequired,
};

export default CardPaymentPlaceHolder;
