const SplitGatewayMapping = {
  'default': {
    'chargebee_gateway_id': process.env.bluesnapUKChargebeeGatewayId,
  },
  'us': {
    'default': {
      'chargebee_gateway_id': process.env.bluesnapUSChargebeeGatewayId,
    },
  },
};

export default SplitGatewayMapping;
