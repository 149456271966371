import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Form, Row} from 'react-bootstrap';
import {CardComponent, CardNumber, CardExpiry, CardCVV}
  from '@chargebee/chargebee-js-react-wrapper';
import htmr from 'htmr';
import cookie from 'react-cookies';

import {KeyValuePair} from '../../Application/Shared';
import {StateCodesUS} from '../Data/StateCodes';
import * as Utils from '../Utils';
import ChargebeeProcessor from '../Gateways/Processors/ChargebeeProcessor';
import PurchaseUtils from '../Utils/PurchaseUtils';
import useScript from '../Utils/ScriptLoader';
import CardPaymentPlaceHolder from '../CardPaymentPlaceholder';
import GatewayDetails from '../Data/GatewayDetails';
import Error from '../Utils/Error';
import GreenShield from
  '../../../static/images/payment/green-shield.inline.svg';
import styles from '../payments.module.scss';
import VisaCardIcon from
  '../../../static/images/payment/visa-logo.inline.svg';
import MasterCardIcon from
  '../../../static/images/payment/mastercard.inline.svg';
import AmericanExpressIcon from
  '../../../static/images/payment/american-express.inline.svg';
import JcbCardIcon from
  '../../../static/images/payment/jcb.inline.svg';
import DiscoverCardIcon from
  '../../../static/images/payment/discover.inline.svg';

const CARD_ELEMENT_OPTIONS = {
  classes: {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  },
  placeholder: {
    number: '1234 1234 1234 1234',
    expiry: 'MM / YY',
    cvv: 'CVC',
  },
  style: {
    base: {
      'color': '#333',
      'fontWeight': '500',
      'fontFamily': 'Lato',
      'fontSize': '18px',
      '::placeholder': {
        color: '#CCCCCC',
        fontSize: '14px',
        fontWeight: 100,
        fontFamily: 'Lato',
      },
    },
  },
};
const ELEMENTS_EMPTY_ERRORS = {
  'number': 'Card number cannot be blank',
  'expiry': 'Card expiry cannot be blank',
  'cvv': 'CVV/CVC cannot be blank',
};

const getPointerForElementType = (elementType) => {
  return {
    'number': 'card_number',
    'expiry': 'expiry_date',
    'cvv': 'cvv',
  }[elementType];
};

const validateElementsData = (elementsData) => {
  // build error pointers
  const updatedErrorPointers = {};
  for (const key in elementsData) {
    if (elementsData.hasOwnProperty(key)) {
      if (elementsData[key]['error'] || elementsData[key]['empty']) {
        updatedErrorPointers[getPointerForElementType(key)] =
          elementsData[key]['error'] ||
          (elementsData[key]['empty'] && ELEMENTS_EMPTY_ERRORS[key]);
      }
    }
  }
  if (Object.keys(updatedErrorPointers).length > 0) {
    throw new Error({
      type: Error.TYPES.INTERNAL_ERROR,
      message: 'Chargebee elements validation failed',
      nextAction: Error.NEXT_ACTIONS.UNKNOWN,
      errorPointers: updatedErrorPointers,
    });
  }
};

const ChargebeeCardPayment = ({
  pageData,
  pageOfferTypeTexts,
  billingConsent,
  showZipcodeInput, showStateCodeInput,
  setLoadingState,
  handleError,
  cardMeta, setCardMeta,
  onPurchaseSuccess, onReactivationSuccess,
  product, student, subscription, language,
  cardProcessor, retryWithGatewaySequence,
}) => {
  // script hook :)
  const status = useScript(
      'https://js.chargebee.com/v2/chargebee.js',
  );
  // refs
  const componentRef = useRef(null);
  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);
  const cardSubmitButtonRef = useRef(null);
  // state hooks
  const [chargebeeInstanceReady, setChargebeeInstanceReady] = useState(false);
  const [chargebeeFieldsLoaded, setChargebeeFieldsLoaded] = useState(false);
  const [cardholder, setCardholder] = useState('');
  const [errorPointers, setErrorPointers] = useState({});
  const [timeoutId, setTimeoutId] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateName: '',
    stateCode: '',
    zip: '',
    country: student.attributes.address.country,
  });
  const [elementsData, setElementsData] = useState({
    'number': {
      name: 'Card number',
      empty: true,
    },
    'expiry': {
      name: 'Card expiry',
      empty: true,
    },
    'cvv': {
      name: 'CVV/CVC',
      empty: true,
    },
  });

  // effect hooks
  useEffect(() => {
    if (status === 'ready') {
      window.Chargebee.init({
        site: process.env.chargebeeSite,
        publishableKey:
          GatewayDetails[cardProcessor.chargebee_gateway_id]?.
              ['gateway_public_key'],
      });
      setChargebeeInstanceReady(true);
    }
  }, [status]);

  let currentOfferTypeText =
     pageOfferTypeTexts.getValue(product.freeTrialPeriod);
  if (!currentOfferTypeText) {
    currentOfferTypeText = `4 weeks free`;
  }

  // TODO: duplicate.. move to utils
  const getFreeTrialPeriodText = () => {
    let freeTrialText = `4 Weeks`; // default text
    if (product.freeTrialPeriod > 0) {
      if (product.freeTrialPeriod === 14) {
        freeTrialText = `2 Weeks`;
      } else if (product.freeTrialPeriod != 28) {
        freeTrialText =
              `${product.freeTrialPeriod} ${product.freeTrialPeriodUnit}`;
      }
    }
    return freeTrialText;
  };

  if (cardSubmitButtonRef.current) {
    const freeTrialPeriodRef =
      Utils.getElementRefByClassName(cardSubmitButtonRef, 'free-trial-period');
    if (freeTrialPeriodRef) {
      freeTrialPeriodRef.textContent = getFreeTrialPeriodText()?.toLowerCase();
    }
  }

  // Method to trigger on card component ready
  const onReady = (e) => {
    setChargebeeFieldsLoaded(true);
  };

  // Validation error handling
  const onChange = (e) => {
    if (elementsData[e.field]) {
      setElementsData((elementsData) => {
        const updatedElementsData = {...elementsData};
        updatedElementsData[e.field].empty = e.empty;
        updatedElementsData[e.field]['error'] = e.error ?
        `${updatedElementsData[e.field].name} is invalid`: '';
        return updatedElementsData;
      });
    }
  };

  // TODO: refactor & push to parent layers
  // current implementation makes no sense with processors :|
  const processCardPayment = (e) => {
    e.preventDefault();
    // NOTE: sniffing bugs
    setLoadingState('Your request is being processed. Please wait...');
    const ctaLabel =
      e.target?.querySelector('button[type=submit]')?.textContent;
    Utils.triggerCTAClickAction(
        student.id,
        Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        ctaLabel, 'CardSubmit',
    );
    processChargebeePayment();
  };

  const processChargebeePayment = async () => {
    try {
      validateElementsData(elementsData);
      const firstName = cardholder.split(' ').slice(0, 1).join(' ');
      const lastName = cardholder.split(' ').slice(1).join(' ');
      const chargebeeAdditionalData = {
        firstName,
        lastName,
        email: student.attributes.email,
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        addressLine3: '',
        // state: billingAddress.stateName,
        // stateCode: billingAddress.stateCode,
        // countryCode: billingAddress.country.toUpperCase(),
        // zip: billingAddress.zip,
      };
      const chargebeeProcessor = new ChargebeeProcessor(
          cardProcessor.chargebee_gateway_id,
      );
      const purchaseUtils = new PurchaseUtils(chargebeeProcessor);
      let coursePicked = student.attributes.profile.course_picked;
      if (cookie.load('coursePicked')) {
        coursePicked = cookie.load('coursePicked');
      }
      let tokenDetails;
      try {
        tokenDetails =
        await componentRef.current.tokenize(chargebeeAdditionalData);
      } catch (e) {
        let error;
        // morph error
        if (e.code === 'ChargebeeClientError') {
          error = new Error({
            type: Error.TYPES.VALIDATION_ERROR,
            message: e.message,
            nextAction: Error.NEXT_ACTIONS.UNKNOWN,
            errorPointers: {
              '_all': e.message,
            },
          });
        } else {
          error = new Error({
            type: Error.TYPES.INTERNAL_ERROR,
            message: e.message,
            nextAction: Error.NEXT_ACTIONS.UNKNOWN,
            errorPointers: {
              '_all': e.message,
            },
          });
        }
        throw error;
      }
      // REACTIVATION FLOW
      if (Utils.eligibleForSubReactivation(subscription)) {
        const reactivationParams = {
          product,
          subscription,
          cbToken: tokenDetails.token,
          billingAddress: billingAddress,
          pageKind: Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
          studentId: student.id,
        };
        await purchaseUtils.reactivateSubscriptionWithTrial(
            reactivationParams,
        );
        onReactivationSuccess(
            cardProcessor.gateway, cardProcessor.chargebee_gateway_id,
        );
      } else {
        // PURCHASE SUBSCRIPTION
        const purchaseParams = {
          product,
          cbToken: tokenDetails.token,
          billingAddress: billingAddress,
          coursePicked,
          pageKind: Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
          paymentSourceType: 'card',
          studentId: student.id,
        };
        await purchaseUtils.purchaseSubscription(purchaseParams);
        onPurchaseSuccess(
            cardProcessor.gateway, cardProcessor.chargebee_gateway_id,
        );
      }
    } catch (error) {
      console.log('CHARGEBEE TOKENISATION FAILED', error);
      Utils.triggerCardSubmissionFailure(
          Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION, language,
          cardProcessor.gateway, cardProcessor.chargebee_gateway_id, error,
      );
      let processed = false;
      if (error.nextAction===Error.NEXT_ACTIONS.UNKNOWN &&
        Object.keys(error.errorPointers).length > 0) {
        processed = displayError(error.errorPointers);
      }
      if (!processed) {
        processed = retryWithGatewaySequence(error);
      }
      if (!processed) {
        handleError(error);
      }
    }
  };

  const displayError = (errorPointers) => {
    // only displaying one of the errors
    // TODO: tweak for individual field error handling
    let scrollToRef;
    for (const pointer in errorPointers) {
      if (errorPointers.hasOwnProperty(pointer)) {
        const errorMessage = errorPointers[pointer];
        switch (pointer) {
          case 'card_number': {
            scrollToRef = cardNumberRef;
            setErrorPointers({
              'card_number': errorMessage,
            });
          }
            break;
          case 'expiry_date': {
            scrollToRef = cardExpiryRef;
            setErrorPointers({
              'expiry_date': errorMessage,
            });
          }
            break;
          case 'cvv': {
            scrollToRef = cardCvcRef;
            setErrorPointers({
              'cvv': errorMessage,
            });
          }
            break;
          default:
            break;
        }
        break;
      }
    }
    if (scrollToRef) {
      setLoadingState(false);
      if (timeoutId) {
        clearInterval(timeoutId);
      }
      const tempTimeoutId = setTimeout(() => {
        setErrorPointers({});
      }, 5000);
      setTimeoutId(tempTimeoutId);
      scrollToRef?.current?.scrollIntoView();
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {/* need following placeholder? */}
      {!chargebeeFieldsLoaded && (
        <CardPaymentPlaceHolder
          pageData={pageData}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          showZipcodeInput={showZipcodeInput}
          product={product}
        />
      )}
      {chargebeeInstanceReady && (
        <CardComponent ref={componentRef}
          currency = {product?.currency.toUpperCase()}
          icon = {false}
          locale = {language}
          styles={CARD_ELEMENT_OPTIONS.style}
          classes={CARD_ELEMENT_OPTIONS.classes}
          placeholder={CARD_ELEMENT_OPTIONS.placeholder}
          onReady={onReady}
          onChange={onChange}
        >
          <Form className="sh-payment-form"
            onSubmit={
              (e) => processCardPayment(e)
            }>
            <Form.Group controlId="cardNumber"
              ref={cardNumberRef}>
              <Form.Label>
                Card Number
              </Form.Label>
              <div className={styles.paymentMethodLogos}>
                <VisaCardIcon />
                <MasterCardIcon />
                <AmericanExpressIcon />
                <DiscoverCardIcon />
                <JcbCardIcon/>
              </div>
              <CardNumber
                className="sh-input"
              />
              <div className={styles.cardFieldError}>
                <span>
                  {errorPointers['card_number']}
                </span>
              </div>
            </Form.Group>
            <Row>
              <Form.Group controlId="cardExpiry"
                ref={cardExpiryRef}>
                <Form.Label className="card-expiry">
                  EXPIRY DATE
                </Form.Label>
                <CardExpiry
                  className="sh-input"
                />
                <div className={styles.cardFieldError}>
                  <span>
                    {errorPointers['expiry_date']}
                  </span>
                </div>
              </Form.Group>
              <Form.Group controlId="cardCvc"
                ref={cardCvcRef}>
                <Form.Label>
                  CVC/CVV
                </Form.Label>
                <CardCVV
                  className="sh-input"
                />
                <div className={styles.cardFieldError}>
                  <span>
                    {errorPointers['cvv']}
                  </span>
                </div>
              </Form.Group>
            </Row>
            <Form.Group controlId="cardName">
              <Form.Label>
                NAME
              </Form.Label>
              <Form.Control
                className="sh-input"
                onChange={(e) => setCardholder(e.target.value)}
                value={cardholder}
                type="text"
                name="name"
                placeholder={`CARDHOLDER NAME`}
                autoComplete="off"
                required={true}
              />
            </Form.Group>
            {showZipcodeInput &&
              <Form.Group controlId="cardZipCode">
                <Form.Label>
                  ZIP CODE
                </Form.Label>
                <Form.Control
                  className="sh-input"
                  onChange={(e) =>
                    setBillingAddress(
                        {...billingAddress, zip: e.target.value},
                    )}
                  value={billingAddress.zip}
                  type="text"
                  name="zipCode"
                  placeholder={`ZIP CODE`}
                  autoComplete="off"
                  required={true}
                />
              </Form.Group>}
            {showStateCodeInput &&
              <Form.Group controlId="cardStateCode">
                <Form.Label>
                  STATE
                </Form.Label>
                <Form.Control
                  className={`sh-input ${styles.ccState}`}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      stateCode: e.target.value,
                    },
                    )}
                  value={billingAddress.stateCode}
                  as='select'
                  name="stateCode"
                  placeholder={`STATE`}
                  required={true}
                >
                  <option disabled hidden value=''>
                    STATE
                  </option>
                  {StateCodesUS.map((stateCode) => {
                    return (
                      <option key={stateCode.code}
                        value={stateCode.code}>
                        {stateCode.name}
                      </option>);
                  })}
                </Form.Control>
              </Form.Group>}
            <Card className={styles.ctaSection}>
              <div className={styles.topSection}>
                <p className={styles.price}>
                  Today&apos;s total :&nbsp;
                  {product.freeTrialPeriod ? Utils.getFormattedPrice(0,
                      product.currency, product.currencySymbol) :
                    Utils.getFormattedPrice(product.totalPrice,
                        product.currency, product.currencySymbol)}
                </p>
                <p className={styles.weeks}>
                  <span>{currentOfferTypeText}</span>
                  <span>•</span>
                  <span>Cancel at any time</span>
                </p>
              </div>
              <Button
                ref={cardSubmitButtonRef} type="submit"
                className={styles.payBtn}>
                {htmr(pageData.paymentsection.cta)}
              </Button>
              <div className={styles.encryptionWrapper}>
                <p className={styles.text}>
                  Secure payments
                </p>
                <div className={styles.logo}>
                  <GreenShield />
                  <p>
                    128-bit SSL Encryption
                  </p>
                </div>
              </div>
            </Card>
            {billingConsent}
            <div className={styles.subConsentText}>
              {htmr(pageData.paymentsection.contactinfo)}
            </div>
          </Form>
        </CardComponent>
      )}
    </>
  );
};

ChargebeeCardPayment.propTypes = {
  pageData: PropTypes.object.isRequired,
  pageOfferTypeTexts: PropTypes.instanceOf(KeyValuePair).isRequired,
  billingConsent: PropTypes.node.isRequired,
  showZipcodeInput: PropTypes.bool.isRequired,
  showStateCodeInput: PropTypes.bool.isRequired,
  setLoadingState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  cardMeta: PropTypes.object.isRequired,
  setCardMeta: PropTypes.func.isRequired,
  onPurchaseSuccess: PropTypes.func.isRequired,
  onReactivationSuccess: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  language: PropTypes.string.isRequired,
  cardProcessor: PropTypes.object.isRequired,
  retryWithGatewaySequence: PropTypes.func.isRequired,
};

export default ChargebeeCardPayment;
