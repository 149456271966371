import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Form, Row} from 'react-bootstrap';
import {Frames, CardNumber, ExpiryDate, Cvv} from 'frames-react';
import htmr from 'htmr';
import cookie from 'react-cookies';

import {KeyValuePair} from '../../Application/Shared';
import {StateCodesUS} from '../Data/StateCodes';
import * as Utils from '../Utils';
import Error from '../Utils/Error';
import CheckoutProcessor from '../Gateways/Processors/CheckoutProcessor';
import PurchaseUtils from '../Utils/PurchaseUtils';
import useScript from '../Utils/ScriptLoader';
import CardPaymentPlaceHolder from '../CardPaymentPlaceholder';
import GreenShield from
  '../../../static/images/payment/green-shield.inline.svg';
import styles from '../payments.module.scss';
import VisaCardIcon from
  '../../../static/images/payment/visa-logo.inline.svg';
import MasterCardIcon from
  '../../../static/images/payment/mastercard.inline.svg';
import AmericanExpressIcon from
  '../../../static/images/payment/american-express.inline.svg';
import DiscoverCardIcon from
  '../../../static/images/payment/discover.inline.svg';
import CheckoutComLogo from
  '../../../static/images/payment/checkout_com.inline.svg';
// import TroySecure from
//   '../../../static/images/payment/cctype_troysecure.png';
import MasterSecure from
  '../../../static/images/payment/cctype_mastersecure.png';
import VisaSecure from
  '../../../static/images/payment/cctype_visasecure.png';

const CHECKOUT_LOCALES = {
  'de': 'DE-DE',
  'en': 'EN-GB',
  'es': 'ES-ES',
  'fr': 'FR-FR',
  'it': 'IT-IT',
};

const FRAME_STYLES = {
  base: {
    color: '#333',
    fontWeight: 500,
    fontFamily: 'Lato',
    fontSize: '18px',
  },
  placeholder: {
    base: {
      color: '#CCCCCC',
      fontSize: '14px',
      fontWeight: 100,
      fontFamily: 'Lato',
    },
  },
};

const FRAMES_EMPTY_ERRORS = {
  'card-number': 'Card number cannot be blank',
  'expiry-date': 'Card expiry cannot be blank',
  'cvv': 'CVV/CVC cannot be blank',
};

const getFrameClassName = (frameElement, framesData, framesFocusData) => {
  const classNames = ['sh-input'];
  if (framesFocusData[frameElement].focus) {
    classNames.push('focus');
  } else if (framesData[frameElement].error) {
    classNames.push('invalid');
  }
  return classNames.join(' ');
};

const getPointerForFrameElement = (frameElement) => {
  return {
    'card-number': 'card_number',
    'expiry-date': 'expiry_date',
    'cvv': 'cvv',
  }[frameElement];
};

const validateFramesData = (framesData) => {
  // build error pointers
  const updatedErrorPointers = {};
  for (const key in framesData) {
    if (framesData.hasOwnProperty(key)) {
      if (framesData[key]['error'] || framesData[key]['empty']) {
        updatedErrorPointers[getPointerForFrameElement(key)] =
          framesData[key]['error'] ||
          (framesData[key]['empty'] && FRAMES_EMPTY_ERRORS[key]);
      }
    }
  }
  // weird case
  if (Object.keys(updatedErrorPointers).length === 0 &&
    (typeof Frames !== 'undefined') && !Frames.isCardValid) {
    updatedErrorPointers['_all'] = 'Invalid card details';
  }
  if (Object.keys(updatedErrorPointers).length > 0) {
    throw new Error({
      type: Error.TYPES.VALIDATION_ERROR,
      message: 'Checkout elements validation failed',
      nextAction: Error.NEXT_ACTIONS.UNKNOWN,
      errorPointers: updatedErrorPointers,
    });
  }
};

const CheckoutCardPayment = ({
  pageData,
  pageOfferTypeTexts,
  billingConsent,
  showZipcodeInput, showStateCodeInput,
  setLoadingState,
  handleError,
  cardMeta, setCardMeta,
  onPurchaseSuccess, onReactivationSuccess,
  product, student, subscription, language,
  cardProcessor,
  retryWithGatewaySequence,
}) => {
  // refs
  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);
  const iFrameRef = useRef(null);
  // hack for state value closure issue on 3rd party callback
  const productRef = useRef();
  const billingAddressRef = useRef();
  const cardholderRef = useRef();
  // const purchaseInProgressRef = useRef();
  // state hooks
  const [framesData, setFramesData] = useState({
    'card-number': {
      name: 'Card number',
      empty: true,
      error: '',
    },
    'expiry-date': {
      name: 'Card expiry',
      empty: true,
      error: '',
    },
    'cvv': {
      name: 'CVV/CVC',
      empty: true,
      error: '',
    },
  });
  // since no order present for validation / focus events
  const [framesFocusData, setFramesFocusData] = useState({
    'card-number': {
      focus: false,
    },
    'expiry-date': {
      focus: false,
    },
    'cvv': {
      focus: false,
    },
  });
  const [cardholder, setCardholder] = useState('');
  const namePlaceHolderRef=useRef(null);
  const nameInputRef=useRef(null);
  // const [disableNameField, setDisableNameField] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    country: student?.attributes.address?.country||'',
    stateCode: student?.attributes.address?.region||'',
    zip: '',
  });
  // TODO: pull up
  const [errorPointers, setErrorPointers] = useState({});
  const [timeoutId, setTimeoutId] = useState(false);
  // const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  // script hook :)
  const status = useScript(
      'https://cdn.checkout.com/js/framesv2.min.js',
  );

  // effect hooks
  useEffect(() => {
    // toggleNameFiled();
    setBillingAddress({
      country: student?.attributes.address.country||'',
      stateCode: student?.attributes.address.region||'',
      zip: '',
    });
  }, [student]);

  productRef.current = product;
  billingAddressRef.current = billingAddress;
  cardholderRef.current = cardholder;
  // purchaseInProgressRef.current = purchaseInProgress;
  let currentOfferTypeText = pageOfferTypeTexts.getValue(product.freeTrialDays);
  if (!currentOfferTypeText) {
    currentOfferTypeText = `4 weeks free`;
  }
  // const toggleNameFiled = () => {
  //   if (['au', 'za', 'ca', 'gb'].
  //       includes(student?.attributes.address.country)) {
  //     const firstName = student?.attributes.name.first_name;
  //     const lastName = student?.attributes.name.last_name;
  //     const fullNmae = `${firstName} ${lastName}`;
  //     if (window.innerWidth < 640) {
  //       setCardholder(fullNmae);
  //     }
  //     // setDisableNameField(true);
  //   }
  // };
  const frameFocus = (e) => {
    const currentFrameFocusData = framesFocusData[e.element];
    setFramesFocusData({
      ...framesFocusData,
      [e.element]: {
        ...currentFrameFocusData,
        focus: true,
      },
    });
  };

  const frameBlur = (e) => {
    const currentFrameFocusData = framesFocusData[e.element];
    setFramesFocusData({
      ...framesFocusData,
      [e.element]: {
        ...currentFrameFocusData,
        focus: false,
      },
    });
  };

  const frameValidationChanged = (e) => {
    if (framesData[e.element]) {
      setFramesData((framesData) => {
        const updatedFramesData = {...framesData};
        updatedFramesData[e.element].empty = e.isEmpty;
        updatedFramesData[e.element].error =
          e.isValid ? '' : `${updatedFramesData[e.element].name} is invalid`;
        return updatedFramesData;
      });
    }
  };

  const cardTokenized = async (result) => {
    try {
      let coursePicked = student.attributes.profile.course_picked;
      if (cookie.load('coursePicked')) {
        coursePicked = cookie.load('coursePicked');
      }
      // Note: using local copy of meta
      const updatedCardMeta = {
        cardType: result.card_type?.toLowerCase(),
        cardBrand: result.scheme?.toLowerCase(),
      };
      setCardMeta(updatedCardMeta);
      const checkoutProcessor =
        new CheckoutProcessor(cardProcessor.chargebee_gateway_id, iFrameRef);
      const purchaseUtils = new PurchaseUtils(checkoutProcessor);
      // REACTIVATION FLOW
      if (Utils.eligibleForSubReactivation(subscription)) {
        const reactivationParams = {
          product: productRef.current,
          subscription,
          cardToken: result.token,
          cardHolder: cardholderRef.current,
          billingAddress: billingAddressRef.current,
          pageKind: Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
          studentId: student.id,
        };
        await purchaseUtils.reactivateSubscriptionWithTrial(
            reactivationParams,
        );
        onReactivationSuccess(
            cardProcessor.gateway, cardProcessor.chargebee_gateway_id,
            updatedCardMeta,
        );
      } else {
        // PURCHASE SUBSCRIPTION
        const purchaseParams = {
          product: productRef.current,
          cardToken: result.token,
          cardHolder: cardholderRef.current,
          billingAddress: billingAddressRef.current,
          coursePicked,
          pageKind: Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
          paymentSourceType: 'card',
          studentId: student.id,
        };
        await purchaseUtils.purchaseSubscription(purchaseParams);
        await onPurchaseSuccess(
            cardProcessor.gateway, cardProcessor.chargebee_gateway_id,
            updatedCardMeta,
        );
      }
    } catch (error) {
      Utils.triggerCardSubmissionFailure(
          Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION, language,
          cardProcessor.gateway, cardProcessor.chargebee_gateway_id, error,
      );
      let processed = retryWithGatewaySequence(error);
      if (!processed) {
        if (error.nextAction === Error.NEXT_ACTIONS.UNKNOWN &&
          Object.keys(error.errorPointers).length > 0) {
          processed = displayError(error.errorPointers);
        }
      }
      // setPurchaseInProgress(false);
      if (!processed) {
        handleError(error);
      }
    }
  };

  // TODO: check for individual field error
  const cardTokenizationFailed = (e) => {
    console.log('Checkout card tokenisation failed', e.message);
    const error = new Error({
      type: Error.TYPES.INTERNAL_ERROR,
      message: 'Checkout tokenisation failed',
      nextAction: Error.NEXT_ACTIONS.UNKNOWN,
      errorPointers: {
        '_all': 'Invalid card details',
      },
    });
    Utils.triggerCardSubmissionFailure(
        Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION, language,
        cardProcessor.gateway, cardProcessor.chargebee_gateway_id, error,
    );
    // setPurchaseInProgress(false);
    handleError(error);
  };

  // TODO: refactor & push to parent layers
  // current implementation makes no sense with processors :|
  const processCardPayment = (e) => {
    e.preventDefault();
    // NOTE: sniffing bugs
    setLoadingState('Your request is being processed. Please wait...');
    const ctaLabel =
      e.target?.querySelector('button[type=submit]')?.textContent;
    Utils.triggerCTAClickAction(
        student.id,
        Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        ctaLabel, 'CardSubmit',
    );
    processCheckoutPayment();
  };

  const processCheckoutPayment = async () => {
    try {
      // if (!purchaseInProgressRef.current) {
      validateFramesData(framesData);
      // allowing to resubmit the payment form In case of card Declined.
      Frames.enableSubmitForm();
      Frames.submitCard();
      // setPurchaseInProgress(true);
      // } else {
      //   console.log('Duplicate attempt detected');
      // }
    } catch (error) {
      Utils.triggerCardSubmissionFailure(
          Utils.PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION, language,
          cardProcessor.gateway, cardProcessor.chargebee_gateway_id, error,
      );
      let processed = false;
      if (error.nextAction === Error.NEXT_ACTIONS.UNKNOWN &&
        Object.keys(error.errorPointers).length > 0) {
        processed = displayError(error.errorPointers);
      }
      if (!processed) {
        handleError(error);
      }
    }
  };

  const displayError = (errorPointers) => {
    // only displaying one of the errors
    // TODO: tweak for individual field error handling
    let scrollToRef;
    for (const pointer in errorPointers) {
      if (errorPointers.hasOwnProperty(pointer)) {
        const errorMessage = errorPointers[pointer];
        switch (pointer) {
          case 'card_number': {
            scrollToRef = cardNumberRef;
            setErrorPointers({
              'card_number': errorMessage,
            });
          }
            break;
          case 'expiry_date': {
            scrollToRef = cardExpiryRef;
            setErrorPointers({
              'expiry_date': errorMessage,
            });
          }
            break;
          case 'cvv': {
            scrollToRef = cardCvcRef;
            setErrorPointers({
              'cvv': errorMessage,
            });
          }
            break;
          default:
            break;
        }
        break;
      }
    }
    if (scrollToRef) {
      setLoadingState(false);
      if (timeoutId) {
        clearInterval(timeoutId);
      }
      const tempTimeoutId = setTimeout(() => {
        setErrorPointers({});
      }, 5000);
      setTimeoutId(tempTimeoutId);
      scrollToRef?.current?.scrollIntoView();
      return true;
    } else {
      return false;
    }
  };

  const getStudentName = () => {
    const firstName = student?.attributes.name.first_name;
    const lastName = student?.attributes.name.last_name;
    const fullName = `${firstName} ${lastName}`;
    return fullName;
  };

  const toggleNameInput = () => {
    if (nameInputRef.current.style.display==='flex') {
      nameInputRef.current.style.display='none';
      namePlaceHolderRef.current.style.display='flex';
    } else {
      nameInputRef.current.style.display='flex';
      namePlaceHolderRef.current.style.display='none';
    }
  };

  return (
    <>
      {status === 'ready' && (
        <Frames
          config={{
            // debug: true,
            publicKey: cardProcessor.gateway_public_key,
            localization: CHECKOUT_LOCALES[language] || 'EN-GB',
            style: FRAME_STYLES,
            cardholder: {
              name: cardholder,
              billingAddress: {
                state: billingAddress.stateCode,
                country: billingAddress.country,
                zip: billingAddress.zip,
              },
            },
          }}
          ready={() => {
            console.log('Checkout Ready');
          }}
          frameFocus={frameFocus}
          frameBlur={frameBlur}
          frameValidationChanged={frameValidationChanged}
          cardTokenized={cardTokenized}
          cardTokenizationFailed={cardTokenizationFailed}
        >
          <Form className="sh-payment-form"
            onSubmit={processCardPayment}>
            <Form.Group controlId="cardNumber"
              ref={cardNumberRef}
            >
              <Form.Label>
                Card Number
              </Form.Label>
              <div className={styles.paymentMethodLogos}>
                <VisaCardIcon />
                <MasterCardIcon />
                <AmericanExpressIcon />
                <DiscoverCardIcon />
                <CheckoutComLogo />
              </div>
              <CardNumber
                className={
                  getFrameClassName('card-number', framesData, framesFocusData)
                }
              />
              <div className={styles.cardFieldError}>
                <span>
                  {errorPointers['card_number']}
                </span>
              </div>
            </Form.Group>
            <Row>
              <Form.Group controlId="cardExpiry"
                ref={cardExpiryRef}
              >
                <Form.Label className="card-expiry">
                  EXPIRY DATE
                </Form.Label>
                <ExpiryDate
                  className={
                    getFrameClassName(
                        'expiry-date',
                        framesData, framesFocusData,
                    )
                  }
                />
                <div className={styles.cardFieldError}>
                  <span>
                    {errorPointers['expiry_date']}
                  </span>
                </div>
              </Form.Group>
              <Form.Group controlId="cardCvc"
                ref={cardCvcRef}
              >
                <Form.Label>
                  CVC/CVV
                </Form.Label>
                <Cvv
                  className={
                    getFrameClassName('cvv', framesData, framesFocusData)
                  }
                />
                <div className={styles.cardFieldError}>
                  <span>
                    {errorPointers['cvv']}
                  </span>
                </div>
              </Form.Group>
            </Row>
            <Form.Group controlId="nameInput">
              <Form.Label>
                NAME
              </Form.Label>
              <div className='sh-input disabled-input'
                style={{display: 'none'}}
                ref={namePlaceHolderRef}
                id="namePlaceHolder">
                <p>{getStudentName()}</p>
                <button onClick={() => {
                  // setDisableNameField(false);
                  setCardholder('');
                  toggleNameInput();
                }
                }>Change</button>
              </div>
              <Form.Control
                className="sh-input"
                ref={nameInputRef}
                data-name={getStudentName()}
                onChange={(e) => setCardholder(e.target.value)}
                value={cardholder}
                type="text"
                name="name"
                placeholder={`CARDHOLDER NAME`}
                autoComplete="off"
                required={true}
              />
            </Form.Group>
            {showZipcodeInput &&
              <Form.Group controlId="cardZipCode">
                <Form.Label>
                  ZIP CODE
                </Form.Label>
                <Form.Control
                  className="sh-input"
                  onChange={(e) =>
                    setBillingAddress(
                        {...billingAddress, zip: e.target.value},
                    )}
                  value={billingAddress.zip}
                  type="text"
                  name="zipCode"
                  placeholder={`ZIP CODE`}
                  autoComplete="off"
                  required={true}
                />
              </Form.Group>}
            {showStateCodeInput &&
              <Form.Group controlId="cardStateCode">
                <Form.Label>
                  STATE
                </Form.Label>
                <Form.Control
                  className={`sh-input ${styles.ccState}`}
                  onChange={(e) =>
                    setBillingAddress({
                      ...billingAddress,
                      stateCode: e.target.value,
                    },
                    )}
                  value={billingAddress.stateCode}
                  as='select'
                  name="stateCode"
                  placeholder={`STATE`}
                  required={true}
                >
                  <option disabled hidden value=''>
                    STATE
                  </option>
                  {StateCodesUS.map((stateCode) => {
                    return (
                      <option key={stateCode.code}
                        value={stateCode.code}>
                        {stateCode.name}
                      </option>);
                  })}
                </Form.Control>
              </Form.Group>}
            <Card className={styles.ctaSection}>
              <div className={styles.topSection}>
                <p className={styles.price}>
                  Today&apos;s total :&nbsp;
                  {product.freeTrialPeriod ? Utils.getFormattedPrice(0,
                      product.currency, product.currencySymbol) :
                    Utils.getFormattedPrice(product.totalPrice,
                        product.currency, product.currencySymbol)}
                </p>
                <p className={styles.weeks}>
                  <span>{currentOfferTypeText}</span>
                  <span>•</span>
                  <span>Cancel at any time</span>
                </p>
              </div>
              {/* TODO : Make the text dynamic */}

              <Button type="submit" className={styles.payBtn}>
                {htmr(pageData.paymentsection.cta)}
              </Button>
              <div className={styles.encryptionWrapper}>
                <p className={styles.text}>
                  Secure payments
                </p>
                <div className={styles.logo}>
                  <GreenShield />
                  <p>
                    128-bit SSL Encryption
                  </p>
                </div>
              </div>
            </Card>
            <div className={styles.ccTypeLogo}>
              <img src={MasterSecure} />
              <img src={VisaSecure} />
            </div>
            <div className={styles.poweredBy}>
              <p>Powered by</p>
              <CheckoutComLogo />
            </div>
            {billingConsent}
            <div className={styles.subConsentText}>
              {htmr(pageData.paymentsection.contactinfo)}
            </div>
          </Form>
        </Frames>
      )}
      <iframe
        ref={iFrameRef}
        className={styles.authPage}
        src={''}
        style={{display: 'none'}}
        onLoad={(e) => {
          console.log('3DS frame ready');
        }}
      ></iframe>
      {/* need following placeholder? */}
      {status !== 'ready' && (
        <CardPaymentPlaceHolder
          pageData={pageData}
          pageOfferTypeTexts={pageOfferTypeTexts}
          billingConsent={billingConsent}
          showZipcodeInput={showZipcodeInput}
          product={product}
        />
      )}
    </>
  );
};

CheckoutCardPayment.propTypes = {
  pageData: PropTypes.object.isRequired,
  pageOfferTypeTexts: PropTypes.instanceOf(KeyValuePair).isRequired,
  billingConsent: PropTypes.node.isRequired,
  showZipcodeInput: PropTypes.bool.isRequired,
  showStateCodeInput: PropTypes.bool.isRequired,
  setLoadingState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  cardMeta: PropTypes.object.isRequired,
  setCardMeta: PropTypes.func.isRequired,
  onPurchaseSuccess: PropTypes.func.isRequired,
  onReactivationSuccess: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  language: PropTypes.string.isRequired,
  cardProcessor: PropTypes.object.isRequired,
  retryWithGatewaySequence: PropTypes.func.isRequired,
};


export default CheckoutCardPayment;
