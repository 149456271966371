import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Nav, Tab} from 'react-bootstrap';
import {KeyValuePair} from '../Application/Shared';
import FAQSection from './FAQSection';
import CardPayment from './CardPayment';
// import ApplePayPayment from './Gateways/ApplePayPayment';

import {triggerCTAClickAction, PAGE_KINDS} from './Utils';
import CardIcon from '../../static/images/payment/credit_card.inline.svg';
import styles from './payments.module.scss';
// import PayPalIcon from '../../static/images/payment/paypal-logo.inline.svg';
// import PayPalPayment from './Gateways/PayPalPayment';
// import AmazonPayPayment from './Gateways/AmazonPayPayment';
// import CardLessPayment from './Gateways/GoCardLessPayment';
// import AmazonPayIcon from
//   '../../static/images/payment/amazonpay-logo.inline.svg';
import PaymentJustification from './Shared/PaymentJustification';
import LazyLoad from 'react-lazyload';
// const PAYPAL_SUPPORTED_CURRENCIES =
// ['eur', 'aud', 'cad', 'gbp', 'nzd', 'usd'];
// const APPLE_PAY_VERSION = 3;

const PaymentSection = ({
  pageData,
  pageOfferTypeTexts,
  billingConsent,
  errorPointers,
  setLoadingState,
  handleError,
  // skipPaymentEnabled,
  onPurchaseSuccess, onReactivationSuccess,
  product, student, subscription,
  language,
}) => {
  // refs
  const errorRef = useRef(null);

  // state hooks
  const [paymentModes, setPaymentModes] = useState(['card']);
  const [currentPaymentMode, setCurrentPaymentMode] = useState('card');

  // effect hooks
  useEffect(()=> {
    const updatedPaymentModes = ['card'];
    // if (PAYPAL_SUPPORTED_CURRENCIES.includes(product.currency)) {
    //   updatedPaymentModes.push('paypal');
    // }
    // if (process.env.AppicationEnv === 'staging') {
    //   if (window.ApplePaySession &&
    //     ApplePaySession.supportsVersion(APPLE_PAY_VERSION) &&
    //     ApplePaySession.canMakePayments()) {
    //     updatedPaymentModes.push('applepay');
    //   }
    // }
    // if (product.currency == 'usd') {
    //   updatedPaymentModes.push('amazonpay');
    // }
    // if (product.billingCountry == 'gb') {
    //   updatedPaymentModes.push('gocardless');
    // }
    // if (skipPaymentEnabled) {
    //   updatedPaymentModes.push('skip');
    // }
    setPaymentModes(updatedPaymentModes);
    // if (product.currency == 'usd') {
    //   setCurrentPaymentMode('amazonpay');
    // } else
    // if (product.billingCountry == 'gb') {
    //   setCurrentPaymentMode('card');
    // } else
    if (product.billingCountry) {
      setCurrentPaymentMode('card');
    }
    // renderApplePay();
  }, [product]);
  // }, [product, skipPaymentEnabled]);

  // const renderApplePay = () => {
  //   const applepayTabLink
  //   = document.querySelector('.apple-pay-button-white');
  //   if (applepayTabLink) {
  //     applepayTabLink.style['-apple-pay-button-style'] = 'white';
  //   }
  // };
  // only for generic messages as of now
  useEffect(() => {
    if (errorPointers['_all']) {
      errorRef?.current?.scrollIntoView();
    }
  }, [errorPointers]);

  const onPaymentModeChange = (newPaymentMode) => {
    triggerCTAClickAction(
        student.id,
        PAGE_KINDS.PREMIUM_TRIAL_SUBSCRIPTION,
        newPaymentMode, 'PaymentMethodChanged',
    );
    setCurrentPaymentMode(newPaymentMode);
  };

  return (
    <div className={styles.paymentModeContainer}>
      <div className={styles.paymentModes}>
        <div className={styles.header}>
          {pageData.paymentsection.heading}
        </div>
        <PaymentJustification product={product} styles={styles} />
        <Tab.Container
          activeKey={currentPaymentMode}
          onSelect={onPaymentModeChange}
        >
          <Nav style={{display: (paymentModes.length>1 ? 'flex': 'none')}}>
            {paymentModes.includes('gocardless') && <Nav.Item>
              <Nav.Link eventKey="gocardless">
                <span></span>
                <span>Direct Debit</span>
              </Nav.Link>
            </Nav.Item>}
            {paymentModes.includes('card') && <Nav.Item>
              <Nav.Link eventKey="card">
                <span></span>
                <CardIcon />
                <span>Card</span>
              </Nav.Link>
            </Nav.Item>}
            {paymentModes.includes('paypal') && <Nav.Item>
              <Nav.Link eventKey="paypal">
                <span></span>
                {/* <PayPalIcon /> */}
              </Nav.Link>
            </Nav.Item>}
            {paymentModes.includes('applepay') &&
            <Nav.Item className='apple-pay-nav-link'>
              <Nav.Link eventKey="applepay">
                <span></span>
                <div className="apple-pay-button apple-pay-button-white">
                  <p className='logo'></p>
                </div>
              </Nav.Link>
            </Nav.Item>}
            {/* {paymentModes.includes('amazonpay') && <Nav.Item>
              <Nav.Link eventKey="amazonpay">
                <span></span>
                <AmazonPayIcon />
              </Nav.Link>
            </Nav.Item>} */}
            {/* {paymentModes.includes('skip') && <Nav.Item>
              <Nav.Link eventKey="skip">
                <span></span>&nbsp;
                <span>Proceed Without Card</span>
              </Nav.Link>
            </Nav.Item>} */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="card">
              {currentPaymentMode === 'card' && <CardPayment
                pageData={pageData}
                pageOfferTypeTexts={pageOfferTypeTexts}
                billingConsent={billingConsent}
                setLoadingState={setLoadingState}
                handleError={handleError}
                onPurchaseSuccess={onPurchaseSuccess}
                onReactivationSuccess={onReactivationSuccess}
                student={student}
                product={product}
                subscription={subscription}
                language={language}
              />}
            </Tab.Pane>
            {/* <Tab.Pane eventKey="paypal">
              <PayPalPayment
                pageData={pageData}
                pageLabels={pageLabels}
                errorLabels={errorLabels}
                pageOfferTypeTexts={pageOfferTypeTexts}
                billingConsent={billingConsent}
                setLoadingState={setLoadingState}
                handleError={handleError}
                onPurchaseSuccess={onPurchaseSuccess}
                onReactivationSuccess={onReactivationSuccess}
                student={student}
                product={product}
                subscription={subscription}
                language={language}
              />
            </Tab.Pane> */}
            {/* <Tab.Pane eventKey="applepay">
              <ApplePayPayment
                pageData={pageData}
                pageLabels={pageLabels}
                errorLabels={errorLabels}
                pageOfferTypeTexts={pageOfferTypeTexts}
                billingConsent={billingConsent}
                setLoadingState={setLoadingState}
                handleError={handleError}
                onPurchaseSuccess={onPurchaseSuccess}
                onReactivationSuccess={onReactivationSuccess}
                student={student}
                product={product}
                subscription={subscription}
                language={language}
              />
             </Tab.Pane> */}
            {/* {<Tab.Pane eventKey="gocardless">
              {currentPaymentMode==='gocardless'&&<CardLessPayment
                pageData={pageData}
                pageLabels={pageLabels}
                product={product}
                pageOfferTypeTexts={pageOfferTypeTexts}
                errorLabels={errorLabels}
                billingConsent={billingConsent}
                setLoadingState={setLoadingState}
                handleError={handleError}
                onPurchaseSuccess={onPurchaseSuccess}
                onReactivationSuccess={onReactivationSuccess}
                student={student}
                subscription={subscription}
                language={language}
              />}
            </Tab.Pane>} */}
            {/* <Tab.Pane eventKey="amazonpay">
              {paymentModes.includes('amazonpay') && <AmazonPayPayment
                pageData={pageData}
                pageLabels={pageLabels}
                errorLabels={errorLabels}
                pageOfferTypeTexts={pageOfferTypeTexts}
                billingConsent={billingConsent}
                setLoadingState={setLoadingState}
                handleError={handleError}
                onPurchaseSuccess={onPurchaseSuccess}
                onReactivationSuccess={onReactivationSuccess}
                student={student}
                product={product}
                subscription={subscription}
                language={language}
              />}
            </Tab.Pane> */}
            {/* <Tab.Pane eventKey="skip">
              <> -- </>
            </Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
        <div ref={errorRef}
          className={`payment-error show`}>
          <span>
            {errorPointers['_all']}
          </span>
        </div>
      </div>
      <LazyLoad height={490} offset={0} once={true}>
        <FAQSection
          title={pageData.faqsection.title}
          questions={pageData.faqsection.questions}
          isForMobile={true}
        />
      </LazyLoad>
    </div>
  );
};

PaymentSection.propTypes = {
  pageData: PropTypes.object.isRequired,
  pageOfferTypeTexts: PropTypes.instanceOf(KeyValuePair).isRequired,
  billingConsent: PropTypes.node.isRequired,
  errorPointers: PropTypes.object.isRequired,
  setLoadingState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  // skipPaymentEnabled: PropTypes.bool.isRequired,
  onPurchaseSuccess: PropTypes.func.isRequired,
  onReactivationSuccess: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  student: PropTypes.object,
  subscription: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default PaymentSection;
