const GatewayDetails = {
  [process.env.stripeDefaultChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeDefaultPublicKey,
  },
  [process.env.stripeUSChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeUSPublicKey,
  },
  [process.env.stripeUKChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeUKPublicKey,
  },
  [process.env.stripeAcademyftChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeAcademyftPublicKey,
  },
  [process.env.stripeLiveAcademyChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeLiveAcademyPublicKey,
  },
  [process.env.stripeSEGChargebeeGatewayId]: {
    'gateway': 'stripe',
    'gateway_public_key': process.env.stripeSEGPublicKey,
  },
  [process.env.checkoutDefaultChargebeeGatewayId]: {
    'gateway': 'checkout_com',
    'gateway_public_key': process.env.checkoutDefaultPublicKey,
  },
  [process.env.checkoutUSChargebeeGatewayId]: {
    'gateway': 'checkout_com',
    'gateway_public_key': process.env.checkoutUSPublicKey,
  },
  [process.env.checkoutUKChargebeeGatewayId]: {
    'gateway': 'checkout_com',
    'gateway_public_key': process.env.checkoutUKPublicKey,
  },
  [process.env.bluesnapUSChargebeeGatewayId]: {
    'gateway': 'chargebee',
    'gateway_public_key': process.env.chargebeeBrowserApiKey,
  },
  [process.env.bluesnapUKChargebeeGatewayId]: {
    'gateway': 'chargebee',
    'gateway_public_key': process.env.chargebeeBrowserApiKey,
  },
  [process.env.adyenDefaultChargebeeGatewayId]: {
    'gateway': 'adyen',
    'gateway_public_key': process.env.adyenDefaultPublicKey,
  },
};

export default GatewayDetails;
